import React from "react";
import styled from "styled-components";

const Content = styled.img`
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  max-width: ${({ maxWidth }) => maxWidth || null};
  margin: ${({ margin }) => margin || 0};
  padding-right: ${({ paddingRight }) => paddingRight || "0"}rem;
  border: ${({ border }) => border || "none"};
`;

const Image = ({ src, width, height, maxWidth, margin, paddingRight, border }) => {
  return (
    <Content
      src={src}
      width={width}
      height={height}
      maxWidth={maxWidth}
      margin={margin}
      paddingRight={paddingRight}
      border={border}
    />
  );
};

export default Image;
