import React from "react";
import styled from "styled-components";
import { SecondaryColor, WhiteColor } from "../../../constants/colors";

const StyledButton = styled.button`
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  position: absolute;
  -webkit-transition: -webkit-transform .25s, opacity .25s;
  -moz-transition: -moz-transform .25s, opacity .25s;
  transition: transform .25s, opacity .25s;
  border: ${SecondaryColor} ;
  opacity: .25;


  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid ;
    border-right-style: solid;
    border-radius: 5px;
    transform: scale(0.1, 1);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.3s;
    background: ${WhiteColor};
  }

  &:hover {
    color: ${SecondaryColor};

    &:before {
      opacity: 1;
      transform: scale(1, 1);
    }

    &:after {
      opacity: 0;
      transform: scale(0.1, 1);

    }
  }
`;

const CloseButton = ({ title, onClick, padding, fontSize }) => <StyledButton onClick={onClick}
                                                                        padding={padding}
                                                                        fontSize={fontSize}>{title}</StyledButton>;

export default CloseButton;
