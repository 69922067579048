import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Jump from '../../../assets/images/portfolio/jump/jump-card2.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const JumpPage = () => {
    return (
        <CaseDetailContainer caseName="Jump">
            <Helmet>
                <title>SourceMates - Jump </title>
            </Helmet>
            <Title text="Jump platform" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
               <b> THE CHALENGE </b><br />
                After the successful completion of the first two projects, the client approached us again with a request to implement a marketing platform. The task was to create a platform for engaging third-party organizations that conduct marketing business <br />
                <br/><b>Tech stack:</b> <br />
                - javascript <br />
                - React.js <br />
                - Gatsby.js <br />
                - node.js <br /> <br />
            </Text>
            <Image src={Jump} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b> THE SOLUTION</b><br />
                Within a really short period of time, SourceMates managed to create a  web-application that supports the full-cycle hiring process of a subcontractors: from creating and posting a job offer to hiring a right company and approving the offer. A company representative can manage the status of the third-party companies through a personal Dashboard. The platform also has an option to communicate with other users through messages and share files and it also can send notifications to the users on the configured events. Notifications work both as push notifications within the web-app and as email notifications.
                <br /> <br /><b>RESULTS</b><br />
                The Jump platform is developed for companies that want to grow in digital marketing through successful storytelling, for this purpose the Blog and Success Story sections were created on the platform
            </Text>

        </CaseDetailContainer>
    );
};

export default JumpPage;
