export const PrimaryColor = "#07889B";
export const PrimaryLighterColor = "#1783AD";

export const BlueColor = "#4B7AB5";
export const PurpleColor = "#796DAF";
export const LightPurpleColor = "#a182f8";
export const SecondaryColor = "#FF3869";

export const GreenColor = "#31d68a";
export const LightGreenColor = "#B1E97D";
export const YellowColor = "#F9F871";

export const LightBlueColor = '#35ccf2';
export const DarkBlueColor = '#212937';
export const BlackColor = "#000000";
export const WhiteColor = "#ffffff";
export const SecondWhiteColor = "#fff7f8";

export const ArticleColor = "#07889b";
