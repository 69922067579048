import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { SecondaryColor, WhiteColor } from "../../../../constants/colors";
import Image from "../../../UiKit/Image";
import { CommonButton } from "../../../UiKit";
import Title from "../../../UiKit/Title";
import StackBadge from "../../StackBadge";

const Wrapper = styled.div`
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 10px rgb(0 0 0 / 12%);

	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		right: 0;
		bottom: -1px;
		transition: all 600ms ease;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #000000 100%);
	}

	&:after {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 0;
		transition: all 600ms ease;
		background-color: #000000a8;
	}

	&:hover {
		& > div {
			bottom: 0;
		}

		&:before {
			opacity: 0;
		}

		&:after {
			height: 100%;
		}
	}
`;

const StackWrapper = styled.div`
	display: flex;
	gap: 0.625rem;
	flex-wrap: wrap;
	margin-top: 1rem;
	margin-bottom: 1.56rem;
`;

const OverlayBlock = styled.div`
	position: absolute;
	left: 1.875rem;
	right: 1.875rem;
	bottom: -7.5rem;
	z-index: 1;
	padding-bottom: 1.5625rem;
	transition: all 600ms ease;
`;

const CaseCard = ({ title, img, stack, link }) => {
    const history = useHistory();
    return (
	 <Wrapper  onClick={() => history.push( `${history.location.pathname}${link}` )}>
	     <Image src={img} width="100%"/>
	     <OverlayBlock>
		  <Title text={title} color={WhiteColor} weight={300}/>
		  <StackWrapper>
		      {stack && stack.map( i => <StackBadge item={i} key={i}/> )}
		  </StackWrapper>
		  <CommonButton title="READ MORE"/>
	     </OverlayBlock>
	 </Wrapper>
    );
};

export default CaseCard;
