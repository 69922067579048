import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { ContactForm, Map } from "../../ui/components";
import { PageContainer } from "../../ui/containers";
import { ContactCard, PageHeader } from "../../ui/elements";
import MapIcon from "../../assets/images/icon-map.png";
import Phone from "../../assets/images/icon-phone.png";
import Email from "../../assets/images/icon-email.png";
import {device} from "../../utils/devices";
import PL from "../../assets/icons/pl.svg"
import US from "../../assets/icons/us.svg"

const Content = styled.div`
	max-width: 1210px;
	padding: 140px 20px;
	margin: 0 auto;
`;

const ContactCards = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 60px;
	@media ${device.tablet} {
		grid-template-columns: 1fr 1fr 1fr;
	}
\` ;
`;
const StyledMap =styled.div`
@media only screen and (max-width: 768px) {
	display: flex;
	justify-content: center;
	align-self: center;
}
`


const ContactWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    gap: 50px;
    padding-top: 60px;
	@media ${device.tablet} {
		grid-template-columns: 1fr auto;
	}
`
const ContactUsPage = () => {
	const polandPhone = `+48 505 640 642`
	const usaPhone = `+1 609-854-6256`
	const polandAddress = `31 Nowogrodzka, Warsaw, Poland`
	const usaAddress = `6 S 46st Philadelphia 19139`
    return (
	 <PageContainer>
	     <Helmet>
		  <title>SourceMates - Contacts</title>
	     </Helmet>
	     <PageHeader title="Contact Us"/>
	     <Content>
		  <ContactCards>
		      <ContactCard icon={MapIcon} title="Our Location" info={polandAddress} location={usaAddress} us={US} pl={PL}/>
		      <ContactCard icon={Phone} title="Call Us" info={polandPhone} location={usaPhone} pl={PL} us={US}/>
		      <ContactCard icon={Email} title="Write Us Now" info="info@sourcemates.com"/>
		  </ContactCards>
		  <ContactWrapper>
		      <ContactForm/>
			  <StyledMap>
			   <Map width={"45vh"} height={"54vh"}/>
			  </StyledMap>
		  </ContactWrapper>
	     </Content>
	 </PageContainer>
    );
};

export default ContactUsPage;
