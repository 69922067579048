import React from "react";
import styled from "styled-components";
import { TextArticle } from "../../../UiKit";
import Image from "../../../UiKit/Image";

const Wrapper = styled.div`
width: 100%;
`;

const WrapperPhoto = styled.div`
  width: 170px;
  height: 170px;  
  box-shadow: -5px 8px 20px rgb(0 0 0 / 25%);
  border-radius: 50%;
  overflow: hidden;
  margin-bottom :20px;
`;

const TeamArticleCard = ({ photo, name, stack }) => {
  return (
    <Wrapper>
      <WrapperPhoto>
        <Image paddingRight="0" src={photo} width="100%" />
      </WrapperPhoto>
      <TextArticle textAlign="center" marginBottom="2">
        {name}
      </TextArticle>
      <TextArticle textAlign="center" marginBottom="0" opacity={0.6}>
        {stack}
      </TextArticle>
    </Wrapper>
  );
};

export default TeamArticleCard;
