import React from "react";
import styled from "styled-components";
import { SecondWhiteColor } from "../../constants/colors";
import { AnimationContainer } from "../../ui/containers";
import { DecorativeSpinner } from "../../ui/elements";
import { Image, Text, Title } from "../../ui/UiKit";
import Peoples from "../../assets/images/about-us-1.jpeg";
import {device} from "../../utils/devices";

const Wrapper = styled.div`
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
`;

const Content = styled.div`
  position: relative;
  margin: 1rem 0 0 0;
  padding: 8.75rem 0 7.5rem;
  display: grid;
  grid-template-columns: 1fr ;
  align-items: center;
  gap: 3.125rem;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const TextWrapper = styled.div`
margin: 1.25rem 0 0 0;
`;

const ImageWrapper = styled.div`
  display: grid;
  width: fit-content;
  border: 10px solid ${SecondWhiteColor};
  margin: 0 auto;

`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 120px;
  left: 30px;
  @media (max-width: 762px) {
    top: 50px;
    left: 30px
  }
`;

const MainInfoSection = () => {
  return (
    <Wrapper>
      <Content>
        <SpinnerWrapper>
          <DecorativeSpinner />
        </SpinnerWrapper>
        <AnimationContainer side="left" speed={500}>
          <TextWrapper>
            <Title
              size={38}
              text={"SourceMates was established in 2018."}
              margin="0 0 30px 0"
            />
            <Text size={18} opacity={0.8}>
              Our team of engineers consists of passionate and dedicated
              professionals, who are helping to improve and enhance leading
              businesses worldwide.
            </Text>
          </TextWrapper>
        </AnimationContainer>
        <AnimationContainer side="right" speed={500}>
          <ImageWrapper>
            <Image src={Peoples} height={"450px"} />
          </ImageWrapper>
        </AnimationContainer>
      </Content>
    </Wrapper>
  );
};

export default MainInfoSection;
