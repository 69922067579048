import React from "react";
import styled from "styled-components";
import texture from "../../../assets/icons/texture-blue.svg";

const Block = styled.div`
	position: absolute;
	width: ${({ width }) => width || 60}px;
	height: ${({ height }) => height || 300}px;
	top: ${({ top }) => top || 0}px;
//	background: url("${texture}") repeat;
`;

const DecorateTextureBlock = ({ width, height, top }) => {
    return (
	 <Block width={width} height={height} top={top}/>
    );
};

export default DecorateTextureBlock;
