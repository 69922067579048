import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PrimaryLighterColor } from "../../../constants/colors";
import { Text } from "../../UiKit";

const Wrapper = styled.div`
	display: flex;
	width: fit-content;
	justify-content: center;
	align-items: center;
	gap: 10px;
	cursor: pointer;
   	 padding: 10px;

	span, svg {
		transition: 0.2s ease-in;
	    cursor: pointer;
		}
    
    &:hover {
	 span, svg {
	     color: ${PrimaryLighterColor};
	 }		 
    }

`;

const GoBackButton = ({ title }) => {
    const history = useHistory();
    return (
	 <Wrapper onClick={() => history.goBack()}>
	     <FontAwesomeIcon icon={faChevronLeft} size="lg"/>
	     <Text size={16}>{title}</Text>
	 </Wrapper>
    );
};

export default GoBackButton;
