import { useState } from "react";

export const useCounter = (value, time) => {
    const [counterValue, setCounterValue] = useState(1)

    const defaultDelay = Math.round(time / value);

    const startCount = (v) => {
        if(v === value) return;

        if(v < value){
	     setCounterValue(prev => prev + 1);
	     return setTimeout(() => startCount(v + 1), defaultDelay)
	 }
    }

    return [() => startCount(1), counterValue]
}
