import React from "react";
import styled from "styled-components";
import {LightBlueColor, } from "../../../constants/colors";

const Wrapper = styled.div`
    padding: 4px 7px;
    border: 1px solid ${LightBlueColor};
    width: fit-content;
    color: ${LightBlueColor};
    border-radius: 10px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
`

const StackBadge = ({item}) => <Wrapper>{item}</Wrapper>

export default StackBadge;
