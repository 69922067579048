import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Rent from '../../../assets/images/portfolio/rentwallet/rent.png'
import Rente from '../../../assets/images/portfolio/rentwallet/rent3.png'
import { Image, Text, Title } from "../../../ui/UiKit";

const RentWalletPage = () => {
    return (
        <CaseDetailContainer caseName="RentWallet">
            <Helmet>
                <title>SourceMates - RentWallet</title>
            </Helmet>
            <Title text="RentWallet - real estate platform" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>THE CHALLENGE</b><br />
                Application for renting a large number of real estate objects and tracking payments. It offers information dashboard and full automation of rent demands, collections, receipts, reconciliations, transactions and communication history.<br />
                4 applications have been developed for this project:<br/>
                - Landing<br/>
                - CRM(1)<br/>
                - CRM(2)<br/>
                - Mobile app<br/>
                The landing page is an advertisement for the CRM service.
                Through it, you can contact technical support:<br/>
                - email<br/>
                - telegram<br/>
                - WhatsApp<br/>
                As well as register and get to the main portal.
                4 types of subscription are available to the user
                A registered user can add his property and
                link landlord data to it and follow the history of transactions.
                CRM(2) is an independent application for the
                portal administrator, who sees all the information on
                registered users, subscription payments, and
                so the administrator can directly communicate
                with portal customers through CRM
                <br /><b>Tech stack:</b><br />
                -  React.js<br />
                -  Node.js<br />
                -  Redux<br />
                -  prisma<br />
                -  Postgres<br />
                -  cloud function<br />
            </Text><br />
            <Image src={Rent} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b>THE SOLUTION</b><br />
                First, we analyzed and created the terms of reference, based on the idea and customer requirements. Subsequently, a prototype was created, then, the design, backend, and frontend parts of the project were developed step by step in coordination with the client. One of the separate stages was integration with the Bitrix24 platform.
                <br /><br /><b>RESULTS</b><br />
                The result of this work is a developed, complete and successful platform for accounting for real estate, landlords and their payments.
            </Text><br />
                <Image src={Rente} />
        </CaseDetailContainer>
    );
};

export default RentWalletPage;
