import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import Logo from "../../../assets/icons/logo.svg";
import {BlackColor, SecondaryColor, WhiteColor} from "../../../constants/colors";
import { NavigationItem } from "../../elements";
import { Icon } from "../../UiKit";
import { useHistory, useLocation } from "react-router-dom";

const Wrapper = styled.div`
	width: 100%;
	position: fixed;
	top: 0;
	background: ${({ isScrolling }) => isScrolling && "#000000d9"};
	transition: 0.3s ease-out;
	z-index: 50;
`;

const HeaderContent = styled.div`
	display: flex;
	justify-content: space-between;	
	max-width: 1210px;
	margin: 0 auto;
	padding-top: ${({ isScrolling }) => isScrolling ? 10 : 25}px;
	transition: 0.3s ease-out;

`;

const LogoWrapper = styled.div`	
	display: flex;
	align-items: center;
	gap: 15px;
	cursor: pointer;

	svg {
		fill: ${WhiteColor};
	}

	&:hover {
		span {
			color: ${SecondaryColor};
			transition: 0.2s ease;
		}

		svg {
			fill: ${SecondaryColor};
			transition: 0.2s ease;
		}
	}
	@media only screen and (max-width: 768px) {
		padding-top: 0;
		padding-bottom: 0;
	}
`;

const CompanyName = styled.span`
	font-family: Dosis, 'sans-serif';
	font-weight: 600;
	font-size: 1.5rem;
	color: ${WhiteColor};
	@media only screen and (max-width: 768px) {
		padding-bottom: 10px;
	}
`;

const NavigationWrapper = styled.div`
	display: flex;
	gap: 30px;
	margin-right: 15px;
`;
const Navbar = {
	Items: styled.ul`
    display: flex;
	align-items: flex-end;	
    list-style: none;
		
    @media only screen and (max-width:768px) {
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
      flex-direction: column;
      background-color: white;
      padding: 1rem 2rem;
      transition: 0.2s ease-out;
      transform: ${({ openDrawer }) =>
		openDrawer ? `translateX(0)` : `translateX(100%)`};
    }
  `,
	Item: styled.ul`
		gap: 25px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		@media only screen and (max-width: 768px) {
			position: fixed;
			right: 0;
			top: 0;
			height: 100%;
			flex-direction: column;
			background-color: #ffffff;
			padding: 1rem 2rem;
			transition: 0.2s ease-out;
			transform: ${({openDrawer}) =>
				openDrawer ? `translateX(0)` : `translateX(100%)`};
		}
	`
};
const Clutch = styled.div`
	padding: 10px 5px 0 5px;
	width: 145px;
	background-color: ${WhiteColor};
	border-radius: 13px;
	@media only screen and (max-width: 768px) {
		padding: 10px 0 0 0;
		width: 130px;
		background-color: transparent;
	}
`

const HamburgerButton = {
	Wrapper: styled.button`
		height: 3.5rem;
		width: 3rem;
		position: relative;
		font-size: 12px;

		display: none;
		@media only screen and (max-width: 768px) {
			display: block;
		}
		border: none;
		background: transparent;
		outline: none;
		cursor: pointer;

		&:after {
			content: "";
			display: block;
			position: absolute;
			height: 150%;
			width: 150%;
			top: -25%;
			left: -125%;
		}
	`,
	Lines: styled.div`
		top: 50%;
		left: -40%;
		margin-top: -0.125em;

		&,
		&:after,
		&:before {
			
			height: 2px;
			pointer-events: none;
			display: block;
			content: "";
			width: 100%;
			background-color: ${WhiteColor};
			position: absolute;
		}

		&:after {
			
			top: -0.8rem;
		}

		&:before {
			
			top: 0.8rem;
		}
	`
};

const Header = () => {
	const [openDrawer, toggleDrawer] = useState(false);
	const [pageIsScrolling, setPageIsScrolling] = useState( true );
	const location = useLocation();
	const history = useHistory();
	const drawerRef = useRef(null);

	const onScroll = () => {
		setPageIsScrolling( window.pageYOffset > 50 );
	};

	useEffect( () => {
		window.addEventListener( "scroll", onScroll );

		return () => window.removeEventListener( "scroll", onScroll );
	}, [] );

	useEffect(() => {
		const closeDrawer = event => {
			if (drawerRef.current && drawerRef.current.contains(event.target)) {
				return;
			}
			toggleDrawer(false);
		};

		document.addEventListener("mousedown", closeDrawer);
		return () => document.removeEventListener("mousedown", closeDrawer);
	}, []);
	return (
		<Wrapper isScrolling={pageIsScrolling}>
			<HeaderContent isScrolling={pageIsScrolling}>
				<LogoWrapper onClick={() => history.push( "/" )}>
					<Icon icon={Logo} size={55}/>
					<CompanyName>SourceMates</CompanyName>
				</LogoWrapper>
				<NavigationWrapper>
				<HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
					<HamburgerButton.Lines />
				</HamburgerButton.Wrapper>
						<Navbar.Item ref={drawerRef} openDrawer={openDrawer}>
						<NavigationItem  title="Home" link="/" isCurrentRoute={location.pathname === "/"}/>
					<NavigationItem  title="About" insideLinks={[
						["About Us", "/about"],
						["Our Team", "/team"]]}
						isCurrentRoute={location.pathname === "/about" || location.pathname === "/team"}/>
					<NavigationItem title="Cases" link="/cases"
									isCurrentRoute={location.pathname.includes( "/cases" )}/>
					<NavigationItem  title="Articles" link="/articles"
									 isCurrentRoute={location.pathname.includes( "/articles" )}/>
					<NavigationItem  title="Careers" link="/careers" isCurrentRoute={location.pathname === "/careers"}/>
					<NavigationItem title="Contacts" link="/contacts" isCurrentRoute={location.pathname === "/contacts"}/>
						</Navbar.Item>
				</NavigationWrapper>
				</HeaderContent>
			</Wrapper>
	)
}
export default Header;
