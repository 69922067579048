import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import {
    CasesPage,
    GiftCoinPage, InterServiceMarinePage,
    JanusPage,
    JumpPage,
    LakeStudioPage, LamaronPage,
    StanmarPage,
    SudakovPage,
    ThunderITPage,
    LuckytokPage,
    FightclubPage,
    TripyachtPage,

} from "../pages";
import RentWalletPage from "../pages/Cases/RentWalletPage";

const CasesRouter = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={path} exact component={CasesPage} />
            <Route path={`${path}/giftcoin`} exact component={GiftCoinPage} />
            <Route path={`${path}/lake-studio`} exact component={LakeStudioPage} />
            <Route path={`${path}/rentwallet`} exact component={RentWalletPage} />
            <Route path={`${path}/janus`} exact component={JanusPage} />
            <Route path={`${path}/jump`} exact component={JumpPage} />
            <Route path={`${path}/sudakov`} exact component={SudakovPage} />
            <Route path={`${path}/thunder-it`} exact component={ThunderITPage} />
            <Route path={`${path}/stanmar`} exact component={StanmarPage} />
            <Route path={`${path}/lamaron`} exact component={LamaronPage} />
            <Route path={`${path}/interservice-marine`} exact component={InterServiceMarinePage} />
            <Route path={`${path}/luckytok`} exact component={LuckytokPage} />
            <Route path={`${path}/fightclub`} exact component={FightclubPage} />
            <Route path={`${path}/tripyacht`} exact component={TripyachtPage} />
        </Switch>
    )
};

export default CasesRouter
