import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
    DarkBlueColor,
    GreenColor,
    LightBlueColor,
    LightPurpleColor,
    SecondaryColor,
    WhiteColor
} from "../../constants/colors";
import { Text } from "../../ui/UiKit";
import { useCounter } from "../../utils/useCounter";
import {device} from "../../utils/devices";

const Wrapper = styled.div`
	background-color: ${DarkBlueColor};
	display: flex;
	justify-content: center;
	gap: 6.25rem;
	padding: 7.5rem 0 3.75rem;
	@media only screen and (max-width: 768px){
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	@media only screen and (max-width: 580px) {
		display: grid;
		grid-template-columns: 1fr 1fr ;
		padding-left: 160px;
		padding-right: 40px;
	}
`;
const Divider = styled.div`
	border-left: 1px solid ${WhiteColor}50`;

const CounterBlock = styled.div`
	display: grid;
	align-items: center;
	padding-left: 10px;
	margin-bottom: 20px;
	width: 200px;
`;

const Counter = styled.div`
	font-family: Dosis, 'sans-serif';
	font-size: 100px;
	line-height: 1;
	color: ${({ color }) => color};
`;

const StatisticSection = () => {
    const [startProject, projects] = useCounter( 43, 800 );
    const [startClients, clients] = useCounter( 39, 800 );
    const [startCoffee, coffee] = useCounter( 1477, 800 );
    const [startHours, hours] = useCounter( 22, 800 );

    const [statisticIsShowed, setStatisticIsShowed] = useState( false );
    const element = useRef( null );

    const onScroll = () => {
        if ( !element.current ) return;

	 const el = element.current.getBoundingClientRect();
	 const top = el.top;
	 const bottom = el.bottom;
	 const height = el.height;
	 if ( statisticIsShowed === false && ( ( top + height >= 0 ) && ( height + window.innerHeight >= bottom ) ) ) {
	     setStatisticIsShowed( true );
	 }
    };

    useEffect(() => {
	 window.addEventListener( "scroll", onScroll );

	 return () => window.removeEventListener( "scroll", onScroll );
    },[])

    useEffect(() => {
	 if(statisticIsShowed){
	     startProject();
	     startClients();
	     startCoffee();
	     startHours();
	 }
    }, [statisticIsShowed])

    return (
	 <Wrapper ref={element}>
	     <CounterBlock>
		  <Counter color={LightPurpleColor}>{projects}</Counter>
		  <Text color={WhiteColor} opacity={0.5} weight={300}>Projects Done</Text>
	     </CounterBlock>
	     <Divider/>
	     <CounterBlock>
		  <Counter color={GreenColor}>{clients}</Counter>
		  <Text color={WhiteColor} opacity={0.5} weight={300}>Satisfied Clients</Text>
	     </CounterBlock>
	     <Divider/>
	     <CounterBlock>
		  <Counter color={SecondaryColor}>{coffee}</Counter>
		  <Text color={WhiteColor} opacity={0.5} weight={300}>Cups of Coffee</Text>
	     </CounterBlock>
	     <Divider/>
	     <CounterBlock>
		  <Counter color={LightBlueColor}>{hours}</Counter>
		  <Text color={WhiteColor} opacity={0.5} weight={300}>Thousands of hours spent</Text>
	     </CounterBlock>
	 </Wrapper>
    );
};

export default StatisticSection;
