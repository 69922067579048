import React from "react";
import styled from "styled-components";
import { SecondaryColor, WhiteColor } from "../../../../constants/colors";
import Image from "../../../UiKit/Image";
import Text from "../../../UiKit/Text";
import Title from "../../../UiKit/Title";

const Wrapper = styled.div`
	position: relative;
	padding: 40px 35px;
	background-color: ${WhiteColor};
	box-shadow: 0 0 15px rgb(0 0 0 / 10%);
	overflow: hidden;

	&:hover {
		.overlay {
			transform: translateY(0%);
			opacity: 1;
		}
	}
`;

const TitleWrapper = styled.div`
	position: relative;
	margin-bottom: 20px;

	&:after {
		content: "";
		position: absolute;
		width: 30px;
		border: 1px solid ${SecondaryColor};
	}
`;

const OverlayBlock = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	transform: translateY(-100%);
	transition: all 700ms ease;
	background-color: ${SecondaryColor};
	padding: 10px;
`;

const TechnologiesCard = ({title, text, hiddenText, img}) => {

    return (
	 <Wrapper>
	     <Image src={img}/>
	     <TitleWrapper>
		  <Title text={title} size={28} weight={400}/>
	     </TitleWrapper>
	     <Text>{text}</Text>
	     <OverlayBlock className="overlay">
		  <Text color={WhiteColor} weight={400} textAlign="center">{hiddenText}</Text>
	     </OverlayBlock>
	 </Wrapper>
    );
};

export default TechnologiesCard;
