import React from "react";
import styled from "styled-components";
import { BlackColor } from "../../../constants/colors";

const StyledInput = styled.input`
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 30px;
	color: ${BlackColor};
	height: 60px;
	font-size: 15px;
	border-radius: 50px;
	transition: all 300ms ease;
	box-shadow: 0 0 10px rgb(0 0 0 / 10%);
	border: none;
	outline: none;
	font-family: Arial,serif;
`;

const Input = ({ value, onChange, placeholder, type, name }) => <StyledInput value={value} onChange={onChange}
									  placeholder={placeholder} type={type} name={name}/>;

export default Input;
