import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { AnimationContainer, PageContainer } from "../../ui/containers";
import { CaseCard, DecorativeSpinner, PageHeader } from "../../ui/elements";
import { device } from "../../utils/devices";

import Giftcoin from "../../assets/images/portfolio/giftcoin/giftcoin-card.jpg";
import Jump from "../../assets/images/portfolio/jump/jump-card.jpg";
import Sudakov from "../../assets/images/portfolio/sudakov/sudakov-card.jpg";
import InterserviceMarine from "../../assets/images/portfolio/interservice-marine/interservice-marine-card.jpg";
import Stanmar from "../../assets/images/portfolio/stanmar/stanmar-card.jpg";
import Lamaron from "../../assets/images/portfolio/lamaron/lamaron-card.jpg";
import ThunderIt from "../../assets/images/portfolio/thunder-it/thunder-it-card.jpg";
import LakeStudio from "../../assets/images/portfolio/lake-studio/lake-studio-card.jpg";
import Janus from "../../assets/images/portfolio/janus/janus-card.jpg";
import Luckytok from "../../assets/images/portfolio/luckytok/2.jpg";
import Fightclub from "../../assets/images/portfolio/fightclub/1.jpg";
import Tripyacht from "../../assets/images/portfolio/tripyacht/1.jpg";
import RentWallet from "../../assets/images/portfolio/rentwallet/rentlogot.jpg";
import { Title } from "../../ui/UiKit";

const Content = styled.div`
	display: grid;
	grid-template-columns: 1fr ;
	gap: 1.875rem;
	padding: 1.875rem;
	max-width: 1300px;
	margin: auto;
	@media  ${device.tablet}{
		grid-template-columns: 1fr 1fr 1fr 1fr  ;
		}
`;
const TitleWrapper = styled.div`
	position: relative;
	max-width: 1210px;
	padding: 35px 20px 60px;
	margin: 0 auto;
`;

const SpinnerWrapper = styled.div`
	position: absolute;
	right: -20px;
	bottom: 24px;
	@media (max-width: 1250px) {
		right: 10px;
		bottom: 24px;
	}
	@media (max-width: 762px) {
		right: 20px;
		bottom: 24px;
	}
`;

const CasesPage = () => {
	return (
		<PageContainer>
			<Helmet>
				<title>SourceMates - Cases</title>
			</Helmet>
			<PageHeader title="Cases" />
			<TitleWrapper>
				<Title text={"We’ve done lot’s of work, Let’s\n" +
					"Check some from here"} size={42} />
				<SpinnerWrapper>
					<DecorativeSpinner />
				</SpinnerWrapper>
			</TitleWrapper>
			<AnimationContainer side="left" speed={500}>
				<Content>
					<CaseCard img={Fightclub} title="Fightclub" stack={["React native", "Node.js"]} link="/fightclub" />
					<CaseCard img={Luckytok} title="Luckytok" stack={["Node.js", "React"]} link="/luckytok" />
					<CaseCard img={RentWallet} title="RentWallet" stack={["Node.js", "React"]} link="/rentwallet" />
					<CaseCard img={Giftcoin} title="GiftCoin" stack={["NodeJS", "React", "Redux"]} link="/giftcoin" />
					<CaseCard img={Tripyacht} title="Tripyacht" stack={["React", "PHP", "Typescript"]} link="/tripyacht" />
					<CaseCard img={Jump} title="Jump" stack={["Javascript", "React", "Gatsby"]} link="/jump" />
					<CaseCard img={Sudakov} title="Sudakov" stack={["Bootstap4", "Wordpress"]} link="/sudakov" />
					<CaseCard img={LakeStudio} title="Lake Studio" stack={["php", "Shopify"]} link="/lake-studio" />
					<CaseCard img={InterserviceMarine} title="Interservice Marine" stack={["php", "Bootstap4", "Wordpress"]}
						link="/interservice-marine" />					
					<CaseCard img={Lamaron} title="Lamaron" stack={["php", "Bootstap4", "Wordpress"]} link="/lamaron" />
					<CaseCard img={ThunderIt} title="ThunderIT" stack={["php", "Symphony"]} link="/thunder-it" />
					<CaseCard img={Stanmar} title="Stanmar" stack={["React", "NodeJS", "Redux"]} link="/stanmar" />
					<CaseCard img={Janus} title="Janus" stack={["php", "React"]} link="/janus" />
				</Content>
			</AnimationContainer>
		</PageContainer>
	);
};

export default CasesPage;
