import React from "react";
import {useState} from 'react';
import styled from "styled-components";
import { Button, Input, TextArea, Title } from "../../UiKit";
import { send } from 'emailjs-com';

const FormWrapper = styled.div`

`;

const MetaBlock = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	margin-bottom: 30px;
	@media only screen and (max-width: 640px) {
		grid-template-columns: 1fr;
	}
	@media only screen and (max-width: 400px) {
		max-width: 80%;
		width: 80%;
	}
`;

const MessageWrapper = styled.div`
	margin-bottom: 30px;
	@media only screen and (max-width: 400px) {
		max-width: 95%;
		width: 88%;
	}
`;

const ContactForm = () => {
	const [toSend, setToSend] = useState({
		name: "",
		email: "",
		phone: "",
		subject: "",
		message: ""
	});

	// input value

	const handleChange = (e) => {
		setToSend({ ...toSend, [e.target.name]: e.target.value });
	};

	//send mail
	const onSubmit = (e) => {
		e.preventDefault();
		send (
			'service_tlqmryr',
			'template_uz5lfg9',
			toSend,
			'ZgfnkwL92-MTenF0y'
		)
			.then((response) => {
				alert('Thank you for your message!');
				window.location.reload();
				console.log('SUCCESS!', response.status, response.text);
			})
			.catch((err) => {
				console.log('FAILED...', err);
			});
	};

    return (
	 <FormWrapper onSubmit={onSubmit}>
	     <Title text="Contact Form" size={42} weight={600} margin="0 0 45px 0"/>
	     <MetaBlock>
		  <Input
			  type="text"
			  name="name"
			  onChange={handleChange}
			  value={toSend.name}
			  placeholder="Your Name"
		  />
		  <Input
			  type="email"
			  name="email"
			  placeholder="Your Email"
			  onChange={handleChange}
			  value={toSend.email}
		  />
		  <Input
			  type="tel"
			  name="phone"
			  placeholder="Phone"
			  onChange={handleChange}
			  value={toSend.phone}
		  />
		  <Input
			  type="text"
			  name="subject"
			  placeholder="Subject"
			  onChange={handleChange}
			  value={toSend.subject}
		  />
	     </MetaBlock>
	     <MessageWrapper>
		  <TextArea
			  name="message"
			  placeholder="Your Message"
			  onChange={handleChange}
			  value={toSend.message}
		  />

	     </MessageWrapper>
	     <Button
			 type="submit"
			 title="Submit Now"
			 onClick={onSubmit}
		 />

	 </FormWrapper>
    );
};

export default ContactForm;
