import React from "react";
import styled from "styled-components";
import { BlackColor } from "../../../constants/colors";

const Wrapper = styled.p`
	display: ${({ display }) => display || "block"};
	font-family: 'Roboto', sans-serif;
	font-size: ${({ size }) => size || 16}px;
	color: ${({ color }) => color || BlackColor};
	font-weight: ${({ weight }) => weight || 400};
	padding: ${({ padding }) => padding || 0};
	line-height: ${({ lineHeight }) => lineHeight || 1.4};
	text-align: ${({ textAlign }) => textAlign};	
	margin-bottom: ${({ marginBottom }) => marginBottom || 15}px;
	@media (max-width: 576px) {
		font-size: 14px;
	  }
`;

const TextArticle = ({ children, ...styles }) => {
    return (
	 <Wrapper {...styles} >
	     {children}
	 </Wrapper>
    );
};

export default TextArticle;
