import React from "react";
import styled from "styled-components";
import { Image, TextArticle, SubTitleArticle } from "../../../ui/UiKit";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 420px) minmax(300px, 420px);
  grid-gap: 50px;
  align-items: center;
  width: fit-content;
  margin: 100px auto;
  grid-template-areas: ${({ revers }) =>
    revers ? `"photo text"` : `"text photo"`};
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-areas: "photo" "text";
    margin: 70px auto;
    grid-gap: 0;
  }
`;
const Photo = styled.div`
  object-fit: cover;
  box-shadow: -10px 10px 20px rgb(0 0 0 / 25%);
  border-radius: 5px;
  overflow: hidden;
  grid-area: photo;
  @media (max-width: 700px) {
    box-shadow: none;
    width:fit-content;
    margin: auto;
    border-radius: 0;    

      }
`;
const TextBlock = styled.div`
  grid-area: text;
`;

const GridArticle = ({ title, img, array, ...styles }) => {
  const text = array.map((item) => (
    <TextArticle key={array.id}>{item}</TextArticle>
  ));

  return (
    <Wrapper {...styles}>
      <Photo>
        <Image paddingRight="0" src={img} />
      </Photo>
      <TextBlock>
        <SubTitleArticle marginTop="0">{title}</SubTitleArticle>
        {text}
      </TextBlock>
    </Wrapper>
  );
};

export default GridArticle;
