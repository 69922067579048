import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	min-height: 100vh;
	overflow: hidden;
`;

const PageContainer = ({ children }) => {
    return (
	 <Wrapper>
	     {children}
	 </Wrapper>
    );
};

export default PageContainer;
