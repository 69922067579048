import React from "react";
import styled from "styled-components";
import { ArticleContainer, PageContainer } from "../../../ui/containers";
import { PageHeader } from "../../../ui/elements";
import {
  Image,
  TextArticle,
  TitleArticle,
  SubTitleArticle,
} from "../../../ui/UiKit";
import image1 from "../../../assets/images/Articles/crm/image1.webp";
import image2 from "../../../assets/images/Articles/crm/image2.webp";
import image3 from "../../../assets/images/Articles/crm/image3.webp";
import image4 from "../../../assets/images/Articles/crm/image4.webp";
import image5 from "../../../assets/images/Articles/crm/image5.webp";
import image6 from "../../../assets/images/Articles/crm/image6.webp";
import { ArticleColor } from "../../../constants/colors";

const AccentedText = styled.span`
  margin-bottom: 30px;
  font-weight: bold;
  color: #07889b;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: -3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #07889b;
    margin-right: 10px;
    margin-left: 10px;
  }
`;
const Table = styled.table`
  font-size: 16px;
  line-height: 1.3;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
  & th, td {
	border: 1px solid #222222;
  	padding: 10px;
  }
  tr {
	td:nth-child(1) {
	width: 30%;
	vertical-align: middle;
  }
  td:nth-child(2) {
	width: 15%;
	vertical-align: middle;
	text-align: center;
  }
  @media (max-width: 576px) {
		font-size: 12px;
	  }
`;

const CRMPage = () => {
  return (
    <PageContainer>
      <PageHeader title="How to Build a Custom CRM Software For Your Business: Creating Your Own CRM" />
      <ArticleContainer>
        <Image paddingRight="0" src={image1} margin="60px auto 30px" />
        <TitleArticle>
          What is a CRM and Who Needs a Custom CRM development
        </TitleArticle>
        <TextArticle>
          Salesforce and Apptivo, HubSpot and Zoho – if those names are familiar
          to you, that probably means that you’ve already experienced the work
          with some ready-to-use CRM software. It is quite challenging to adapt
          their features for your particular needs, right? Well, that is the sad
          truth behind all global market products like this.
        </TextArticle>
        <TextArticle>
          Regardless of how typical your business may seem, its operating style,
          team, and inner processes are unique. That’s why it is hard to find a
          universal CRM software that works well for everyone. Let’s start from
          the basics and analyze the situation in 2020.
        </TextArticle>
        <TextArticle>
          Customer relationship management (CRM) is not an approach that
          appeared couple of years ago. Any business is based on interactions
          with current and potential customers. The constant question of all
          times is how to store and analyze customers’ data better to provide
          them with better services and get bigger revenue. In 2020, CRM
          software which keeps and analyses information on your clients and
          target audience is an answer.
        </TextArticle>
        <Image paddingRight="0" src={image3} margin="30px auto" />
        <TextArticle>
          CRM software works with information from different sources including
          your website analytics, phone history, email services, and social
          media analytics tools. It is mainly used by sales managers, marketers,
          support agents, and brand analytics.
        </TextArticle>
        <TextArticle>
          Both custom and non-custom CRM software market is likely to become $35
          billion worth by 2023, they say. At the same time, only 47% of
          companies have a 90% ready-made CRM adoption rate, while most of those
          who made a choice in favor of custom tool remain fully satisfied.
        </TextArticle>
        <TextArticle>
          To help you to avoid mistakes, we’d like to list the clear signs of
          your need for custom CRM development:
        </TextArticle>
        <TextArticle>
          <AccentedText>You need specific integrations.</AccentedText>{" "}
          Ready-made CRM software integrates with some popular social networking
          or emailing platforms, but there is no guarantee that you can also
          work with your inner databases or custom online call systems through
          it. The more extraordinary tools your team uses to serve clients and
          record their data the harder it is to find a solution hon mass
          consumption product market such as CRM one. Examples – large
          industrial companies, factories, B2C networks, startups with a unique
          service idea find that challenging to use packaged CRM solutions.
        </TextArticle>
        <TextArticle>
          {" "}
          <AccentedText>
            You don’t want to spend time and money on functions you don’t need.
          </AccentedText>{" "}
          Ready-made CRM software providers don’t have a lot of service
          packages. Usually, not more than 4. They are aimed at various
          businesses, categorizing them mainly by sizes and rarely by operating
          areas. Due to such approach providers stuff each their products with
          numerous features. And if you need just one of them – you pay for
          everything else in the package. In the end, your managers lose working
          time on finding fields and buttons in a sea of ​​similar useless
          elements.
        </TextArticle>
        <TextArticle>
          <AccentedText>You need an identity.</AccentedText> How to create a CRM
          that reflects your identity? Ready-made CRM software is a template.
          You can’t customize its UX and UI design as much as you want and as a
          result, your HR branding may be failed. But it is only a part of the
          problem. Your managers may have difficulties using some CRM options as
          mass software UX is created in favor of the convenience of the
          majority, but not every user. Communication with your customers via
          emails and messages also may become a bit standardized as most of your
          competitors, as well as other brands, could use ready-made CRM
          software with the same features, algorithms, and designs. Have a look
          at your mailbox and find the same style offers from different
          companies.
        </TextArticle>
        <TitleArticle>Benefits of Custom CRM Solutions</TitleArticle>
        <TextArticle>
          Any CRM software is designed to help a business to attract new
          customers and retain existing ones. Usually, they work as a helpful
          tool in all the stages of communication with clients.
        </TextArticle>
        <TextArticle>
          During the first customer-manager meet, some CRM features can make a
          positive impression on a customer, and during further communication,
          the software can cultivate loyal customers by making them personalized
          promotions. Of course, the description of how custom CRM software
          works is not full. But even those facts are enough to explain why
          mass-market solutions don’t work as good as custom ones. The problem
          becomes even greater if you analyze it deeper.
        </TextArticle>
        <TextArticle>
          A ready-made CRM system that doesn’t fully fit into your business
          processes struck your business giving you no possibility to scale it,
          enlarge or even change something.
        </TextArticle>
        <TextArticle>
          To avoid those drawbacks, companies decide to develop their own
          digital tool to manage customer data and improve marketing. There are
          some key custom CRM system benefits, captured by them:
        </TextArticle>
        <TextArticle>
          <AccentedText>The solution is tailored to your needs.</AccentedText>{" "}
          Pre-packaged CRM systems make you pay for some features you are not
          going to use and for those extra features you are going to use, but
          they are not included in any packages. Customized CRM solutions are
          flexible and they are also tailored to your needs. You may include
          anything you want in it, basing on your previous client support and
          sales experience. And if you’d like to test something new or to modify
          several features – your team will manage it easily.
        </TextArticle>
        <TextArticle>
          <AccentedText>Workflow automation.</AccentedText> The user journey of
          your target audience representatives may consist of several specific
          stages. The same is fare for customers retention. Custom CRM system
          could automate marketing, analytics, sales, customer support, delivery
          notification, and billing tasks and save your money. Specific kinds of
          automation are not always possible in case of packaged solutions as
          they are designed to cover only common needs of various businesses.
        </TextArticle>
        <TextArticle>
          <AccentedText>Centralized data aggregation and editing.</AccentedText>{" "}
          Custom-built CRM software gives you full control of what is happening
          inside the company on the digital level. The monitoring function is
          sometimes offered by ready-made CRM software providers, but none of
          them can fix a technical error in the system as quickly as your team.
          Besides that, a custom CRM allows you to work with databases in the
          format that is generally accepted in your company.
        </TextArticle>
        <TextArticle>
          <AccentedText>Customer insights analytics.</AccentedText> Want a
          second wind for your business? Can not figure out at what stage you
          lose customers? To solve these problems, you need a tool to explore
          your customers’ insights. Yes, ready-made CRM systems will help you
          filter out general customer data, such as geography, age, or order
          quantity. But what if you need a combination of filters or features of
          customers that are not considered in the packaged CRM?
        </TextArticle>
        <TextArticle>
          <AccentedText>Scalable CRM app ecosystem.</AccentedText> CRM system is
          not homogeneous. There are a lot of individual applications and
          systems included in it, designed to simplify the life of particular
          departments workers. For example, waiters in a cafe need an
          application for taking orders. And marketers from the same company
          need a tool to design and send promo notifications. That is difficult
          to develop all of CRM apps at the same time. A custom CRM solution
          simplifies the process. You can develop elements of the system in
          small sessions, scale and change them whenever you want.
        </TextArticle>
        <Image paddingRight="0" src={image2} margin="30px auto" />
        <TitleArticle>Before You Build a CRM</TitleArticle>
        <TextArticle>
          Before you start developing a CRM system, you need to pass the
          preoperational stage. It is extremely important to pay full attention
          to key CRM-question about its goals, your digital requirements, and
          functions.
        </TextArticle>
        <TextArticle>
          That is a good idea to decide on your CRM concept having at least one
          representative from each business department in your consulting team.
        </TextArticle>
        <TextArticle>
          List CRM project requirements on the very first brainstorming session
          and then encourage designers and developers to stay in constant
          contact with your consulting team to get better workflow insights.
        </TextArticle>
        <Image paddingRight="0" src={image5} margin="30px auto" />
        <TextArticle>
          <AccentedText>Commercial goals.</AccentedText> Your business has its
          market strategy and goals. Find out how the company’s revenue should
          grow due to the CRM system implementation. Mind that there are various
          approaches to how to use the system. Some software help to reduce
          costs by automating services, the others are aimed at forming a new
          company strategy based on analytics.
        </TextArticle>
        <TextArticle>
          <AccentedText>Legal restrictions.</AccentedText> There is always some
          regulations behind each commercial activity. Sometimes, the privacy
          policy will not let you collect specific information on your customers
          or employees. If you work in a highly-regulated niche and don’t have
          that many ways to promote product between existing customers, reflect
          it in the project requirements document.
        </TextArticle>
        <TextArticle>
          <AccentedText>Quality requirements.</AccentedText> List principal
          features of your CRM like work speed, secure functions, and
          integrations with existing databases. If you want your CRM to meet
          standards such as ISO, FDA, or ASTM, write it down.
        </TextArticle>
        <TextArticle>
          <AccentedText>Defining CRM class.</AccentedText> Customer relation
          management software is addressed to solve different types of issues.
          It is not necessarily that you need an all-inclusive hybrid. However,
          you’ve got to decide in what fields you are going to use the tool.
          Generally, there are 4 of them: operational, analytical,
          communication, and strategic ones.
        </TextArticle>
        <TextArticle>
          <AccentedText>
            Functional requirements and Software Requirements Specifications
            (SRS).
          </AccentedText>
          Name all of the CRM software target groups and try to describe the way
          they are going to interact with it. In order to create a tree of
          functions for the CRM-part dedicated to each company department,
          collect all of their current task types. Remember that each of your
          employee types has his or her own user story. Look on the tool from
          the customer perspective too and add other features to your project.
        </TextArticle>
        <TextArticle>
          <AccentedText>CRM system estimates.</AccentedText> On this stage,
          you’ll probably need to find your team members including developers, a
          designer, and a project manager. But it is also an advantage to have
          all those people from the very beginning. Developers and others help
          you to define tasks and critical paths, estimate deadlines and budget,
          as well as create a project schedule.
        </TextArticle>
        <TitleArticle>
          Customer’s Guide to Creating Custom CRM Solutions
        </TitleArticle>
        <TextArticle>
          On the preoperational stage, your task is to figure out what exactly
          you need inside the CRM system, what income you’d like to get from its
          implementation, and who is going to use it in your company.
        </TextArticle>
        <TextArticle>
          When your answers are ready, go on to the development. The CRM
          software building looks as follows:
        </TextArticle>
        <Image paddingRight="0" src={image4} margin="30px auto" />
        <TextArticle weight="600" color={ArticleColor}>
          Stage 1. Design
        </TextArticle>
        <TextArticle>
          When you are speaking about the CRM system design, you don’t mean
          attractive visual features, but mostly the UX part. Depending on how
          special your business is, the solution can be tricky and
          time-consuming or univocal and straightforward. Make sure that you’ve
          hired a skilled designer with an appropriative experience.{" "}
        </TextArticle>
        <TextArticle>
          The functional design concept should be developed and approved first.
          Then comes UI design. You’ve completed the stage if you have a
          workflow scheme, a description of functional processes, a user
          interface elements library, and a working prototype from your
          designer.{" "}
        </TextArticle>
        <TextArticle weight="600" color={ArticleColor}>
          Stage 2. CRM development and QA{" "}
        </TextArticle>
        <TextArticle>
          Present the design, prototype and workflow diagrams to your
          development team. On this stage, they will have already chosen the
          framework to bring the design to life. They need to approve deadlines
          and project road map. Then building a software architecture and coding
          are taking place.
        </TextArticle>
        <TextArticle>
          The testing stage consists of the software presentation for the
          consulting team from the target company’s departments and load testing
          conducted by QA.
        </TextArticle>
        <TextArticle>
          Mind that most CRM systems have several apps designed for different
          types of workers. They are connected to a single database and have
          functional linking.
        </TextArticle>
        <TextArticle weight="600" color={ArticleColor}>
          Stage 3. CRM software launch and monitoring
        </TextArticle>
        <TextArticle>
          CRM software launch starts from app deployment. To implement a new
          system in the workflow, you need time. Part of the time is spent on
          database integration, another part will be required for the program to
          be installed on all devices of your staff as well as to teach the
          staff how to use it.
        </TextArticle>
        <TextArticle>
          When the system starts working, it’s important to start learning how
          real people behave inside it. Some functions may be useless, and some
          may be missing. If you want to create your own CRM as a truly
          effective product for business automation and management, constantly
          study user behavior and continue product iterations.
        </TextArticle>
        <TitleArticle>Who Will Use a CRM in Your Team?</TitleArticle>
        <TextArticle>
          Creating a custom CRM solution may look similar to any other software
          development and the main thing that differentiates it from others is
          your objectives.
        </TextArticle>
        <TextArticle>
          As we’ve already emphasized, CRM is designed to fulfill your needs in
          several operating areas. If you manage to define what areas need
          strengthening most and list what key points should be covered by the
          future software, you are likely to return your investment.
        </TextArticle>
        <Image paddingRight="0" src={image6} margin="30px auto" />
        <TextArticle>
          Let’s give a short description to all of CRM implementation spheres:
        </TextArticle>
        <TextArticle>
          <AccentedText>
            Business and Sales automation (Operational module).
          </AccentedText>
          CRM raises the level of service automation and saves the salary
          budget. It brings together contact information and order data,
          processing customer order support with fewer efforts from the manager
          side. A most recent type of system is also aimed at sales and
          marketing support automation. The software automates reporting and
          schedules communication.
        </TextArticle>
        <TextArticle>
          <AccentedText>Data collection and storage.</AccentedText> A CRM of
          that kind is a tool of quick customer data getting, saving and
          storing. It creates a general knowledge base of customer contacts and
          preferences.
        </TextArticle>
        <TextArticle>
          <AccentedText>
            Analytics processing and Sales Intelligence (Analytical module).
          </AccentedText>
          The system analyzes customer data that came from various sources,
          studies customer behavior and makes marketing suggestions. Machine
          learning technologies may be used there.
        </TextArticle>
        <TextArticle>
          <AccentedText>Communication (Collaborative module)</AccentedText>The
          CRM processes or store information about emailing and messaging. It
          also records regular calls and helps to manage social networks. This
          type of software could be combined with the previous one (Analytical)
          to become a tool of marketing campaign management and automate
          personalized market offering.
        </TextArticle>
        <TextArticle>
          As usual, a CRM performs a mixture of features covering most of those
          issues and is used by the sales team, marketers and customer support
          managers at the same time.
        </TextArticle>
        <SubTitleArticle>Build a CRM for Sales Team</SubTitleArticle>
        <TextArticle>
          Interaction with clients is both a subject and an object of CRM. The
          sales team is the first employee group who fills the benefits of CRM
          implementation on various levels.
        </TextArticle>
        <TextArticle>
          The system could become a great tool to gather and store lead and
          customer data, an automated sales generator, and a database.
        </TextArticle>
        <TextArticle>
          How to build a CRM? Let’s study CRM sales functionality:
        </TextArticle>
        <TextArticle>
          <AccentedText>Account management.</AccentedText> That is the basic
          feature of any CRM regardless of its form. To turn leads into
          customers and regular customers into loyal one's sales managers need
          to make them a valuable market proposition. And to do so, they require
          information on people’s needs, preferences, and financial status, as
          well as sales history. Sales managers shall have a chance to add
          customers details such as names, age, contact number or Facebook
          profile and then easily find that information using filters.
        </TextArticle>
        <TextArticle>
          <AccentedText>Sales scheduling.</AccentedText> Marketing campaigns
          should be reflected inside sales CRM section. While marketers develop
          promotional events and maintain general offers, sales manager observe
          current offers to make a better proposition to the customer. There is
          also a sales calendar where sales managers or the head of the
          department could set commercial goals, create seasonal strategies and
          assign sales tasks to a particular team member.
        </TextArticle>
        <TextArticle>
          <AccentedText>Sales analytics with filters.</AccentedText> Sales
          department workers study sales trends by viewing CRM system
          dashboards, tables, statistics, and diagrams. Sales managers also
          compare data from different timelines and locations, share them
          between each other and create temporary databases on sale trends.
          Analytics performance tools may have a customization option like
          specific diagram coloring.
        </TextArticle>
        <TextArticle>
          <AccentedText>Automated lead management.</AccentedText> Both customer
          and lead profiles can be filled in automatically. A CRM system can
          record online lead behavior, export user data from open sources, and
          let users tell more about them by showing them questions within site
          triggers.
        </TextArticle>
        <SubTitleArticle>
          Build your own CRM system for Marketers
        </SubTitleArticle>
        <TextArticle>
          A CRM software helps marketers to plan promotional campaigns, set
          commercial objectives, study results and cooperate with the sales
          department.
        </TextArticle>
        <TextArticle>
          The main issue marketers are working on using CRM approach is called
          sales events, or marketing events. Any kind of promotion, including
          seasonal offers or paid targeting ads on Google could be set in the
          CRM calendar, and marketers can use customer database analytics to
          reach the bigger audience knowing its insights.{" "}
        </TextArticle>
        <TextArticle>
          Marketing event could be also based on communication with an existing
          customer. They can receive personalized offers or be informed about
          new things in the store they are going to like.
        </TextArticle>
        <TextArticle>
          Full list of CRM for marketers functions find below:
        </TextArticle>
        <TextArticle>
          <AccentedText>Promo- and sales campaigns management.</AccentedText>{" "}
          Marketers can develop, calculate and run marketing campaigns within
          CRM software. Typically, such campaigns are complex and consist of a
          large number of marketing events, including the launch of advertising
          on social networks, marketplace editing, and sending notifications to
          current customers. A CRM system allows them to implement
          communication-related campaign part effortlessly and create a form for
          storing information about the people who are attracted by the
          marketing event.
        </TextArticle>
        <TextArticle>
          <AccentedText>Exploring customer types.</AccentedText> How to create a
          CRM and why? With CRM software, marketers develop an audience
          segmentation system. Studying the audience by segments allows
          marketers to improve a brand strategy and understand what the product
          should be like in order to be actively bought. Based on that data,
          marketers adjust the portraits of the target audience and then create
          marketing campaigns for them. Customers groups can later be used by
          both marketers and sales managers.
        </TextArticle>
        <TextArticle>
          <AccentedText>Business performance analysis.</AccentedText> A CRM
          software helps marketers to understand which customers were attracted
          through advertising, and which turned on the recommendation. In
          addition to studying the results of marketing campaigns, marketing
          analytics allows marketers to plan campaigns learning from their
          mistakes.
        </TextArticle>
        <SubTitleArticle>Build a CRM for Customer Support</SubTitleArticle>
        <TextArticle>
          Communication between customers and a support team should be
          registered, recorded and studied. There several benefits of service
          automation module.
        </TextArticle>
        <TextArticle>
          First of all, no matter how big your customer support department is,
          that will take a short time for anybody from it to handle the
          particular client problem viewing his or her profile.
        </TextArticle>
        <TextArticle>
          Second, flexible digital database with the list of clients’ most
          popular troubles, questions and complainings can help strategists to
          optimize business processes and make the product better.
        </TextArticle>
        <TextArticle>
          So, what kind of features are usually included in the CRM customer
          support module? Here they are:
        </TextArticle>
        <TextArticle>
          <AccentedText>Informational base.</AccentedText> If a customer support
          department doesn’t use the knowledge base, its representatives should
          do the same actions over and over again to explain to customers how to
          solve their repeated issues. A convenient directory with a search
          engine is the way how to save time, satisfy more users and offload
          staff.
        </TextArticle>
        <TextArticle>
          <AccentedText>Customer support interface.</AccentedText> That is the
          most important issue if you are speaking about CRM for the support
          team. A team member needs software to access customer’s request, view
          it, prioritize and respond as quickly as possible. The system can have
          its own request aggregation engine that proceeds requests
          automatically and heads them to narrow specialists.
        </TextArticle>
        <TextArticle>
          <AccentedText>Automated analytics.</AccentedText> The system should be
          able to save and categorize customer requests. In the future, this
          data will help automate the responses of a customer support team and
          create chatbots. Analyzing customer requests also helps to improve the
          product. The tool works with mail, calls, correspondence in instant
          messengers.Please note that we did not name specific system features,
          as it was in other our articles, where we analyzed the cost and
          features of Uber, Tinder or Snapchat development. In this article, we
          are talking about custom solutions in the field of digital CRM, which
          are normally designed to meet the needs of a particular business.
          Therefore, in the text above, we only identified the main functional
          areas of the product.
        </TextArticle>
        <TitleArticle>Custom CRM Development: Cost and Features</TitleArticle>
        <TextArticle>
          How to determine the final cost of custom CRM which features may
          differ a lot? To orient you in this area and give a few hints, we
          grouped the most popular functions of CRM software and calculated how
          much time is needed to develop them.
        </TextArticle>
        <TextArticle>
          Keep in mind that in addition to the funds spent on CRM development,
          you will also need to allocate design and project management budget
          (up to 30% of the total cost). Separately, training for personnel on
          the use of new tools could be purchased.
        </TextArticle>
        <Table>
          <tr>
            <th></th>
            <th>Time</th>
            <th>Main features</th>
          </tr>
          <tr>
            <td>Team management</td>
            <td>191 hours</td>
            <td>
              Employee general listing, filters and team member search Personal
              page overview and editing – progress statistics access, vocation
              or disease application tool, personal data view Workflow general
              calendar Vacation calendar Employee productivity dashboard
              Communication tool within the team – correspondence and calls
            </td>
          </tr>
          <tr>
            <td>Client management</td>
            <td>93 hours</td>
            <td>
              General customers listing and filters Customer information
              overview Customer search by name or personal code Detailed
              customer profile Editing customer information
            </td>
          </tr>
          <tr>
            <td>Transaction accounting and sales management</td>
            <td>78 hours</td>
            <td>
              Sales dashboard overview Search engine and filters Transaction and
              sales details editing
            </td>
          </tr>
          <tr>
            <td>Task management and contro</td>
            <td>79 hours </td>
            <td>
              Task dashboards overview and task filter Task details editing
            </td>
          </tr>
          <tr>
            <td>Analytics and reports</td>
            <td>183 hours</td>
            <td>
              Report generation for client or employee Generate sales reporting
              Analytics custom performance Infographics
            </td>
          </tr>
          <tr>
            <td>Business process automation</td>
            <td>115 hours</td>
            <td>
              Sales event notifications Automatic task distribution among
              employees based on predetermined criteria Notifications about
              client or employee activity
            </td>
          </tr>
          <tr>
            <td>Integration of third-party modules</td>
            <td>184 hours</td>
            <td>
              Email IP telephony Third-party mobile applications Messengers
              Social network management tools SMS services
            </td>
          </tr>
        </Table>
        <TextArticle>Please contact us to ask any questions !</TextArticle>
      </ArticleContainer>
    </PageContainer>
  );
};

export default CRMPage;
