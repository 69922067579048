import React from "react";
import { Helmet } from "react-helmet";
import { JobsDetailContainer } from "../../../ui/containers";
import { Button, Text, Title } from "../../../ui/UiKit";
import styled from "styled-components";
import { Modal, StackBadge } from "../../../ui/elements";
import useModal from "../../../utils/useModal";
import useApply from "../../../utils/useApply";

const StackWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 34px;
`;

const LeadGenerationPage = () => {
  const { toggle, visible } = useModal();
  const { toApply, onSubmit, handleChange } = useApply();
  return (
    <JobsDetailContainer caseName="Mobile">
      <Helmet>
        <title></title>
      </Helmet>
      <Title text="Lead Generation Specialist" size={44} margin="0 0 20px 0" />
      <Title text="Payment: By agreemenet" size={28} margin="30px 0 40px 0" />
      <Text size={16} lineHeight={1.8} opacity={0.7} margin="0 0 20px 0">
        Your future responsibilities: <br />
        • Generate potential clients through different outreach channels (
        LinkedIn mostly) <br />
        • Search for relevant jobs on UpWork according to the Ideal Client
        Profile
        <br />
        • Apply to relevant UpWork jobs
        <br />
        • Compose cover letters and templates
        <br />
        • Pass generated leads to Sales managers
        <br />
        • Prepare reports on your activities and results
        <br />
        <br />
        We wait from you:
        <br />
        • Good level of English (B2 - C1), written preferred
        <br />
        • Ability to perform repetitive and routine tasks
        <br />
        • Good communication skills
        <br />
        • Proactive and eager to learn
        <br />
        <br />
        Desirable to have:
        <br />
        • Understanding of software development and technology
        <br />
        • Desire to build a career as a lead gen/BDM in IT
        <br />• Experience working with a lead gen in IT
      </Text>
      <StackWrapper>
        <StackBadge item="English (B2-C1)" />
        <StackBadge item="Good communication skills" />
      </StackWrapper>
      <Button title="Respond to vacancy" onClick={toggle} />
      <Modal
        visible={visible}
        toggle={toggle}
        handleChange={handleChange}
        onSubmit={onSubmit}
        toApply={toApply}
      />
    </JobsDetailContainer>
  );
};

export default LeadGenerationPage;
