import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Janus from '../../../assets/images/portfolio/janus/janus-card.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const JanusPage = () => {
    return (
        <CaseDetailContainer caseName="Janus">
            <Helmet>
                <title>SourceMates - Janus</title>
            </Helmet>
            <Title text="Janus" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>The Challenge</b><br />
                The client reached out to us thanks to the recommendation of SourceMates as experienced custom CRM creators from one of our previous customers. Thanks to this fact we've been collaborating with Janus for several years. Janus is a translation company that provides a wide range of professional linguistic services that enable clients to enter new markets with quality localized products and services that are guaranteed to resonate with their target audience.
                <br /><br /><b>Tech stack:</b><br />
                - php<br />
                - React.js<br />
                - Yii2<br /><br />
            </Text>
            <Image src={Janus} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b>The Solution</b><br />
                The developers of our company have created and are currently improving the system that covers the entire range of functionality of the translation process, including accelerated order processing, and allows you to view statistical data and track the progress of ongoing projects and the status of completed ones.
                <br /><br /><b>Result</b><br />
                The idea embodied from scratch mixed with our professionalism has turned into a successfully operating platform in international markets with a large number of satisfied customers.
            </Text>

        </CaseDetailContainer>
    );
};

export default JanusPage;
