import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ArticlesPage, CRMPage, TimeIsMoney } from "../pages";

const ArticlesRouter = () => {
    let { path } = useRouteMatch();
    return (
	 <Switch>
	     <Route path={path} exact component={ArticlesPage}/>
	     <Route path={`${path}/art1`} component={CRMPage}/>
	     <Route path={`${path}/art2`} component={TimeIsMoney}/>
	 </Switch>
    )
};

export default ArticlesRouter
