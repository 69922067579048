import React from "react";
import styled from "styled-components";
import ContentImg1 from "../../assets/images/content-image-1.png";
import { WhiteColor } from "../../constants/colors";
import { AnimationContainer } from "../../ui/containers";
import { MainPageTopGradient } from "../../ui/elements";
import { Image, Text, Title } from "../../ui/UiKit";

const MainBlockContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1210px;
	margin: 20px auto 0;

`;

const TextWrapper = styled.div`
	@media (max-width: 1080px) {
		padding-left: 16px;
	}
`

const MainSection = () => {
    return (
	 <MainPageTopGradient>
	     <MainBlockContent>
		  <TextWrapper>
		      <AnimationContainer side={"top"} speed={700}>
			   <Title text="SOURCE MATES" color={WhiteColor} size={60}/>
		      </AnimationContainer>
		      <AnimationContainer side={"bottom"} speed={700} delay={500}>
			   <Text size={20} color={WhiteColor}
				  weight={300} lineHeight={"1.8rem"}>
				{"Software solutions \nfor business and entertainment"}
			   </Text>
		      </AnimationContainer>
		  </TextWrapper>
		  <AnimationContainer side={"top"} speed={700}>
		      <Image src={ContentImg1} />
		  </AnimationContainer>
	     </MainBlockContent>
	 </MainPageTopGradient>
    );
};

export default MainSection;
