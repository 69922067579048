import React from "react";
import styled from "styled-components";
import { BlackColor } from "../../../constants/colors";

const Wrapper = styled.span`
	display: ${({ display }) => display || "block"};
	font-family: Dosis, 'sans-serif';
	font-size: ${({ size }) => size || 30}px;
	color: ${({ color }) => color || BlackColor};
	font-weight: ${({ weight }) => weight || 600};
	padding: ${({ padding }) => padding || 0};
	margin: ${({ margin }) => margin || 0};
	text-align: ${({ textAlign }) => textAlign || "unset"};
	line-height: ${({ lineHeight }) => lineHeight || 1.3};
	white-space: break-spaces;
	cursor: default;
`;

const Title = ({ text, ...styles }) => {
    return (
	 <Wrapper {...styles}>
	     {text}
	 </Wrapper>
    );
};

export default Title;
