import React from "react";
import styled from "styled-components";
import BG from "../../assets/images/about-us-2.jpg";
import { WhiteColor } from "../../constants/colors";
import { AnimationContainer } from "../../ui/containers";
import { CompetenceCard } from "../../ui/elements";
import { Title } from "../../ui/UiKit";
import {device} from "../../utils/devices";
import develop from "../../assets/images/icons/develop.png"
import design from "../../assets/images/icons/design.png"
import research from "../../assets/images/icons/research.png"

const Wrapper = styled.div`
	position: relative;
	display: grid;
	padding: 120px 0 0;
`;

const BGLayer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("${BG}");
	width: 100%;
	height: 450px;
	z-index: -1;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: #00000090;
	}
`;

const Content = styled.div`
	max-width: 1210px;
	padding: 0 20px;
	margin: 0 auto;
	z-index: 7;
`;

const CardWrapper = styled.div`
display: grid;
    grid-template-columns: 1fr;
    gap: 1.875rem;
	@media ${device.tablet} {
		grid-template-columns: 1fr 1fr 1fr   ;
	}
`

const OurCompetenceSection = () => {
    return (
	 <Wrapper>
	     <BGLayer/>
	     <Content>
		  <Title text="We" color={WhiteColor} size={50} textAlign="center" margin="0 0 50px 0"/>
		  <AnimationContainer side="right" speed={500} startWhenShow>
		  <CardWrapper>
		      <CompetenceCard title={'Develop'} text={'Progressive web applications, websites, mobile apps, massive CRM and e-commerce stores.'} img={develop}/>
		      <CompetenceCard title={'Design'} text={'Unique selling layouts, exceptional screens and unforgettable experience.'} img={design}/>
		      <CompetenceCard title={'Research'} text={'Market problems which we can solve, your competitors and the possibility of realization of your idea.'} img={research}/>
		  </CardWrapper>
		  </AnimationContainer>
	     </Content>
	 </Wrapper>
    );
};

export default OurCompetenceSection;
