import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { BlackColor, SecondaryColor, WhiteColor } from "../../../constants/colors";

const Wrapper = styled.div`
	position: relative;
	padding: 15px 5px 15px 0px;

	&:hover {
		.title {
			color: ${SecondaryColor};
		}

		.dropdown {
			visibility: visible;
			opacity: 1;
			transform: scaleY(1);
		}
	}
`;
const Title = styled.div`
	display: flex;
	align-items: center;
	gap: 0.625rem;
	font-family: Open Sans, "sans-serif";
	color: ${({isCurrentRoute}) => isCurrentRoute ? SecondaryColor :  WhiteColor};
	line-height: 2.15rem;
	font-size: 1em;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s ease;
	text-transform: capitalize;
  @media only screen and (max-width: 768px) {
    color: ${({isCurrentRoute}) => isCurrentRoute ? SecondaryColor :  BlackColor};
  }
`;

const Dropdown = styled.div`
	display: block;
	position: absolute;
	left: 0;
	top: 100%;
	width: 200%;
	background-color: ${WhiteColor};
	padding: 10px 0;

	visibility: hidden;
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	transition: all 0.3s ease;
	z-index: 100;
`;

const DropdownItem = styled.div`
	color: ${({isSelected}) => isSelected ? SecondaryColor : BlackColor};
	padding: 9px 20px;
	font-family: Dosis, "sans-serif";
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		color: ${SecondaryColor};
	}
`;

const NavigationItem = ({ title, link, insideLinks, isCurrentRoute, className }) => {
    const history = useHistory();
    const location = useLocation();
    const withDropdown = !!insideLinks;

    return (
	 <Wrapper>
	     <Title className="title" onClick={() => !withDropdown && history.push( link )} isCurrentRoute={isCurrentRoute}>
		  {title}
		  {withDropdown && <FontAwesomeIcon icon={faChevronDown} size={"sm"}/>}
	     </Title>
	     {withDropdown && <Dropdown className="dropdown">
		  {
		      insideLinks && !!insideLinks.length && insideLinks.map( item => <DropdownItem
			   onClick={() => history.push( item[1] )} key={item[0]} isSelected={location.pathname === item[1]}>{item[0]}</DropdownItem> )
		  }
	     </Dropdown>}

	 </Wrapper>
    );
};

export default NavigationItem;
