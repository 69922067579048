import React from "react";
import styled from "styled-components";
import Triangle from "../../../assets/images/decorative-triangle.png";
import { PrimaryColor, PurpleColor } from "../../../constants/colors";
import { FloatLeftRight, FloatUpDown, Rotate } from "../../../utils/animations";
import Image from "../../UiKit/Image";

const Wrapper = styled.div`
	position: relative;
	width: fit-content;
	margin: ${({ margin }) => margin};
`;

const Circle = styled.div`
	position: absolute;
	width: 70px;
	height: 70px;
	top: ${({ top }) => top}px;
	right: ${({ right }) => right}px;
	z-index: 3;
	margin-left: 10px;
	border-radius: 50%;
	display: inline-block;
	opacity: 0.7;
	background-color: ${({ color }) => color};

	animation: ${({ animation }) => animation} 7s infinite;
`;

const TriangleWrapper = styled.div`
	animation: ${Rotate} 15s infinite;
	width: fit-content;
`;

const DecorativeSpinner = ({ margin }) => {
    return (
	 <Wrapper margin={margin}>
	     <Circle animation={FloatLeftRight} top={-10} right={50} color={PrimaryColor}/>
	     <Circle animation={FloatUpDown} top={10} right={0} color={PurpleColor}/>
	     <TriangleWrapper>
		  <Image src={Triangle}/>
	     </TriangleWrapper>
	 </Wrapper>
    );
};

export default DecorativeSpinner;
