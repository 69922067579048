import React from "react";
import styled from "styled-components";
//import Ic from "../../../../assets/images/service-icon-1.png";
import Image from "../../../UiKit/Image";
import Text from "../../../UiKit/Text";
import Title from "../../../UiKit/Title";

const Wrapper = styled.div`
	padding: 40px 35px;
	background-color: #ffffff;
	box-shadow: 0 0 15px rgb(0 0 0 / 10%);
`

const CompetenceCard = ({text, title, img}) => {
    return (
	 <Wrapper>
	     <Image src={img}/>

	     <Title text={title} weight={300} margin="0 0 25px 0"/>
	     <Text size={16}>
		  {text}
	     </Text>
	 </Wrapper>
    );
};

export default CompetenceCard;
