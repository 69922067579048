import React from "react";
import styled from "styled-components";
import { BlackColor } from "../../../constants/colors";

const Wrapper = styled.span`
	display: ${({ display }) => display || "block"};
	font-family: 'Roboto', sans-serif;
	font-size: ${({ size }) => size || 14}px;
	color: ${({ color }) => color || BlackColor};
	font-weight: ${({ weight }) => weight || 400};
	padding: ${({ padding }) => padding || 0};
	line-height: ${({ lineHeight }) => lineHeight};
	text-align: ${({ textAlign }) => textAlign};
	opacity: ${({ opacity }) => opacity || 1};
	margin: ${({ margin }) => margin || 0};
	margin-bottom: ${({ marginBottom }) => marginBottom || 0};
	padding: ${({ padding }) => padding || 0};
	white-space: pre-line;
	cursor: default;

`;

const Text = ({ children, ...styles }) => {
    return (
	 <Wrapper {...styles} >
	     {children}
	 </Wrapper>
    );
};

export default Text;
