import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Fightclub from '../../../assets/images/portfolio/fightclub/2.jpg'
import Fightclubb from '../../../assets/images/portfolio/fightclub/3.jpg'
import Fightclubs from '../../../assets/images/portfolio/fightclub/4.png'
import Fightclubd from '../../../assets/images/portfolio/fightclub/5.png'
import Fightclubf from '../../../assets/images/portfolio/fightclub/8.png'
import Fightclubg from '../../../assets/images/portfolio/fightclub/7.png'
import { Image, Text, Title } from "../../../ui/UiKit";

const FightclubPage = () => {
    return (
        <CaseDetailContainer caseName="Fightclub">
            <Helmet>
                <title>SourceMates - FightclubPage</title>
            </Helmet>
            <Title text="Fightclub" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>The challenge</b><br />
                    The client came to us with an idea  to create a mobile  cross-platform mobile app for
                arranging fights, with some functions:
                communication between athletes,
                broadcasting fights via smartphone,
                organization of fights
                and blogging.<br /> <br/></Text>    <center><iframe width="1140" height="515" src="https://www.youtube.com/embed/_oJ7JM8_XtE"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe> </center>  <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <br />5 types of roles - fan, fighter, videographer, referee, ring
                fans can follow their favorite fighters and raise
                their popularity,fighters can challenge each other to a fight
                right in the app, videographer and a referee can find a job,
                and the ring can fight on its site.
                Fans can follow their favorite fighters or platforms, like,
                follow the blog, comment on the broadcasts and
                choose their winner.
                Each role has its own unique capabilities
                for working with the app.
                CMS is also implemented for editing news, analyzing users,
                as well as blocking in the system<br /><br />
                <b> Tech Stack:</b><br />
                - Node js(nest)<br />
                - React native<br />
                - Postgres<br />
                - Mongodb<br />
                - Microservices<br />
                - Firebase<br />
                - Wowza<br />
                - Graphql<br />
                - kubernetes<br />
              <br />
            </Text>
            <Image src={Fightclubf} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b>THE SOLUTION</b><br />
                To resolve the server stability problem and eliminate potential high load, we decided to use a microservice architecture. Subsequently, a prototype was created, the design, backend and frontend parts of the project were developed step by step in coordination with the customer. One of the main stages was the connection of wowza broadcasting. <br /><br />
                <b> RESULTS</b><br />
                he result is a full-fledged mobile application that is currently is getting ready to start working.
            </Text>
            <br />
            <Image src={Fightclubg} />
        </CaseDetailContainer>
    );
};

export default FightclubPage;
