import "./styles/main.css";
import { BrowserRouter as Router } from "react-router-dom";
import { MainRouter } from "./routes";

function App() {
    return (
	 <Router>
	     <MainRouter/>
	 </Router>
    );
}

export default App;
