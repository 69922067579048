import React, {useState} from 'react';
import styled from 'styled-components';
import Image from "../Image";
import Attach from "../../../assets/images/icons/6.png"


const Button = styled.button`
  padding: 10px 24px;
  display: inline-block;
  margin-right: auto;
  border-radius: 50px;
  color: #3b3838;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: #ffffff;
  outline: none;
  border: none;
  transition: all 300ms ease;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  font-family: Arial, sans-serif;
  cursor: pointer;
`;


   const FileUploader = props => {
       const [selectedFile, setSelectedFile] = useState(null);
       const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return (
        <>
            <Button onClick={handleClick}>
                <Image src={Attach}/>
            </Button>
            <input
                type="file"
                ref={hiddenFileInput}
                value={selectedFile}
                onChange={(e) => setSelectedFile(e.target.files[0])}
                style={{display: 'none'}}
            />
        </>
    )}

export default FileUploader