import React from "react";
import { Helmet } from "react-helmet";
import { JobsDetailContainer } from "../../../ui/containers";
import { Button, Text, Title } from "../../../ui/UiKit";
import styled from "styled-components";
import { Modal, StackBadge } from "../../../ui/elements";
import useModal from "../../../utils/useModal";
import useApply from "../../../utils/useApply";

const StackWrapper = styled.div`
	display: flex;
	gap: 10px;  
	flex-wrap: wrap;
	margin-top: 40px;
	margin-bottom: 34px;
`;



const FrontEndDeveloperPage = () => {
    const { toggle, visible } = useModal();
    const { toApply, onSubmit, handleChange } = useApply();
    return (
        <JobsDetailContainer caseName="FrontEnd">
            <Helmet>
                <title>Front End developer</title>
            </Helmet>
            <Title text="Front End developer" size={40} margin="0 0 20px 0" />
            <Title text="Payment: By agreement" size={28} margin="30px 0 40px 0" />
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="0 0 20px 0">
                We are looking for a front-end developer who loves and knows how to use React.
                We work with projects in various domains: logistics, art, fintech, medicine and others. Basically, our customers are clients of various industries from the EU and the USA.<br />
                The tasks you have to work on:<br />
                • Participation in the development, testing and introduction of new applications into production;<br />
                • Writing code using React;<br />
                • Working with third-party APIs;<br />
                • Work to improve the performance and reliability of applications;<br />
                • Interaction with other development teams (back-end, QA, designers).<br /><br />
                What you need to know and be able to:<br />
                • Experience in commercial development of web applications from 2 years;<br />
                • Excellent knowledge of JavaScript (ES2015 + / Typescript, React, Redux / MobX, Webpack);<br />
                • Knowledge of HTML / CSS / Material UI;<br />
                • Knowledge of the Git version control system;<br />
                • Experience with REST, GraphQL;<br />
                • Knowledge of design patterns, data structures;<br />
                • Experience with performance analysis tools;<br />
                • Have worked with at least one unit testing framework (such as Jest or others).<br />
                • English from intermediate (B1 and above).<br /><br />
                Will be a plus:<br />
                • Knowledge and experience with GraphQL, React Native, CSS-in-JS, Node.js.<br />
                • Successful experience in problem solving, critical thinking and advanced communication skills.<br /><br />
                We offer:<br />

                · Ability to work both in the office and remotely - as you wish;<br />
                · A well-coordinated team of young and ambitious guys;<br />
                · Flexibility in the work schedule;<br />
                · Pizza Fridays, sports events and fun corporate parties<br />
                · Our corporate culture and team contribute to the creation of comfort and coziness at work.
            </Text>
            <Title text="Stack" size={28} margin="30px 0 40px 0" />
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">JavaSript</Text>
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">React JS</Text>
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">Redux</Text>
            <StackWrapper>
                <StackBadge item="React" />
                <StackBadge item="Redux" />
            </StackWrapper>
            <Button title="Respond to vacancy" onClick={toggle} />
            <Modal visible={visible} toggle={toggle} handleChange={handleChange} onSubmit={onSubmit} toApply={toApply} />
        </JobsDetailContainer>

    );
};

export default FrontEndDeveloperPage;
