import React from "react";
import styled from "styled-components";
import { ArticleContainer, PageContainer } from "../../../ui/containers";
import { PageHeader } from "../../../ui/elements";
import {
  Image,
  TextArticle,
  TitleArticle,
  SubTitleArticle,
} from "../../../ui/UiKit";
import image1 from "../../../assets/images/Articles/timeIsMoney/1f.webp";
import image2 from "../../../assets/images/Articles/timeIsMoney/2f.webp";
import image3 from "../../../assets/images/Articles/timeIsMoney/3f.webp";
import image4 from "../../../assets/images/Articles/timeIsMoney/4f.webp";
import image5 from "../../../assets/images/Articles/timeIsMoney/5f.webp";
import image6 from "../../../assets/images/Articles/timeIsMoney/6f.webp";
import image7 from "../../../assets/images/Articles/timeIsMoney/7f.webp";
import image8 from "../../../assets/images/Articles/timeIsMoney/8f.webp";
import image9 from "../../../assets/images/Articles/timeIsMoney/9f.webp";
import image10 from "../../../assets/images/Articles/timeIsMoney/10f.webp";
import image11 from "../../../assets/images/Articles/timeIsMoney/11f.webp";
import image12 from "../../../assets/images/Articles/timeIsMoney/12f.webp";
import image13 from "../../../assets/images/Articles/timeIsMoney/13.webp";
import team1 from "../../../assets/images/teammates/oit2.jpg";
import team2 from "../../../assets/images/teammates/oit24.jpg";
import team3 from "../../../assets/images/teammates/oit22.jpg";
import team4 from "../../../assets/images/teammates/oit27.jpg";
import team5 from "../../../assets/images/teammates/oit20.jpg";
import team6 from "../../../assets/images/teammates/oit6.jpg";
import team7 from "../../../assets/images/teammates/oit29.jpg";
import team8 from "../../../assets/images/teammates/oit25.jpg";

import GridArticle from "../../../ui/UiKit/GridArticle";
import TeamArticleCard from "../../../ui/elements/Cards/TeamArticleCard";

const TeamBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 170px);
  grid-gap: 60px 30px;
  justify-content: space-between;
  margin: 100px auto;
  max-width: 960px;
  @media (max-width: 700px) {
    grid-template-columns: repeat(8, 170px);
    overflow: auto;
    padding: 0 0 15px 20px;
    margin: 60px auto;
  }
`;

const GridText = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minMax(280px, 1fr));
  grid-column-gap: 70px;
  margin: auto;
  max-width: 960px;
  height: 300px;
  align-items: center;
  & div {
    position: relative;
  }
  & div::before {
    display: block;
    font-weight: bold;
    font-size: 250px;
    color: rgba(7, 136, 155, 0.2);
    position: absolute;
    font-family: "Roboto", sans-serif;
    left: 50%;
    top: 50%;
    line-height: 1;
    transform: translate(-50%, -50%);
  }
  & div:first-of-type::before {
    content: "7";
  }
  & div:last-of-type::before {
    content: "95";
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    & div::before {
      
      
      font-size: 150px;}
  }
`;

const TimeIsMoney = () => {
  return (
    <PageContainer>
      <PageHeader title="WE ARE SOURCEMATES" />
      <ArticleContainer>
        <SubTitleArticle marginTop="100">
          We’ve 11 years experience with our own ecommerce, literally we know
          every button on your website and we can prove it.
        </SubTitleArticle>
        <SubTitleArticle>
          Our team includes senior level software developers. We could do much
          more to help you to get more profit out of your eCommerce.
        </SubTitleArticle>
        <TeamBlock>
          <TeamArticleCard
            photo={team1}
            name="Eugene Semenets"
            stack="Founder"
          />

          <TeamArticleCard
            photo={team2}
            name="Pavel Valakhovich"
            stack="Project Manager"
          />

          <TeamArticleCard
            photo={team3}
            name="Denis Sushkevich"
            stack="Backend Developer"
          />

          <TeamArticleCard
            photo={team4}
            name="Andrey Nosenko"
            stack="Backend Developer"
          />

          <TeamArticleCard
            photo={team5}
            name="Mihail Vorobeychikov"
            stack="Software Developer"
          />

          <TeamArticleCard
            photo={team6}
            name="Nazar Kuksov"
            stack="Software Developer"
          />

          <TeamArticleCard
            photo={team7}
            name="Dmitry Zubakin"
            stack="Frontend Developer"
          />

          <TeamArticleCard
            photo={team8}
            name="Vladislav Rakitski"
            stack="Software Developer"
          />
        </TeamBlock>
        <TextArticle textAlign="center">
          We could do much more to help you to get more profit out of your
          eCommerce. To ensure that, let's start from the very beginning, let’s
          make your eCommerce store faster! We can help you get more returning
          customers and lower bounce rate by speeding up.
        </TextArticle>
        <TitleArticle size="60">Time is money</TitleArticle>
        <TextArticle textAlign="center">
          Especially when it comes to your ecommerce speed. Slow websites lose
          orders to fast websites.
        </TextArticle>
        <GridText>
          <div>
            <TextArticle textAlign="center" size="22" weight="600">
              A one second delay in page response time decreases conversion
              rates by 7 percent Akamai – 2020
            </TextArticle>
          </div>
          <div>
            <TextArticle textAlign="center" size="22" weight="600">
              95% of mobile users to a fast website said they would come back
              again, compared to only 62% for a slow website Google – 2020
            </TextArticle>
          </div>
        </GridText>
        <TextArticle textAlign="center">
          Even a small improvement in your site’s load times will give you an
          edge over the competition. One of the best examples of this is
          Walmart’s improvement in conversions and revenue after increasing
          their site speed. Amazon - 2018
        </TextArticle>
        <TextArticle textAlign="center">
          Google takes speed into consideration when ranking sites. Mobile
          searches outnumbered desktop searches back in 2015, and its share of
          overall search only continues to grow. This means that it’s in
          Google’s best interest to cater its search results to mobile users.
          There could be a number of reasons why your site load time is lagging.
          It could be anything from server load time to image size to the number
          of redirects you have.
        </TextArticle>
        <TitleArticle>We’ll look at 12 of them</TitleArticle>

        <GridArticle
          title="Minimize HTTP requests, minify and combine files"
          array={[
            "An HTTP request is made for downloading the different parts of the page, like images, stylesheets, and scripts, so the more on-page components, the longer it takes for the page to render.",
            "The first step to minimize website’s requests is to look through your files and see if any are unnecessary. Some of them are prime candidates for combining and minifying.",
            "We can lower the number of files by “minifying” and combining. This reduces the size of each file, as well as the total number of files.",
          ]}
          img={image1}
        />

        <GridArticle
          title="Аsynchronous loading for CSS and JavaScript files"
          array={[
            "Scripts like CSS and JavaScript can be loaded in two different ways: Synchronously or Asynchronously.",
            "If your scripts load synchronously, they load one at a time, in the order they appear on the page. If your scripts load asynchronously, some of them will load simultaneously.",
            "Loading files asynchronously can speed up your pages because when a browser loads a page, it moves from top to bottom.",
          ]}
          img={image2}
          revers
        />

        <GridArticle
          title="Defer JavaScript loading"
          array={[
            "Deferring a file means preventing it from loading until after other elements have loaded. If you defer larger files, like JavaScript, you ensure that the rest of your content can load without a delay.",
          ]}
          img={image3}
        />

        <GridArticle
          title="Minimize time to first byte and reduce server response time"
          array={[
            "Time to first byte, or TTFB, is the amount of time a browser has to wait before getting its first byte of data from the server. Google recommends a TTFB of less than 200 ms.",
            "In general, most issues with slow TTFB are caused by either network issues, dynamic content creation, web server configuration, and traffic.",
            "Your TTFB is / сколько у него там/ , this is not a good shape. There could be a few different issues at the root.",
            "One of the biggest factors in how quickly your page loads is the amount of time your DNS lookup takes.",
          ]}
          img={image4}
          revers
        />

        <TextArticle size="22" textAlign="center">
          Your e-commerce website uses we recommend changing them to faster
          ones.
        </TextArticle>

        <GridArticle
          title="We’ll run a compression audit and enable compression"
          array={[
            "Pages with lots of images and other content can often end up being over 100KB in size. As a result, they’re bulky and slow to download.",
            "t’s in your best interest to get your files to the smallest they can be, without sacrificing quality. The smaller your files, the faster they’ll load — and the lower your overall load times will be.",
            "To get a sense of how compression could speed up your site, you can use GIDNetwork.",
          ]}
          img={image5}
        />

        <GridArticle
          title="Reduce external scripts"
          array={[
            "As we mentioned above, the fewer requests your site makes to your server, the faster a page will load. But if they aren’t worth slowing your page speed, we’ll uninstall them.",
            "Knowing which features are adding to your load times will help you better understand your site’s speed. It can also help you make more informed decisions about the features you choose to add to your site in the future.",
          ]}
          img={image6}
          revers
        />

        <GridArticle
          title="Enable browser caching"
          array={[
            "The first time someone comes to your website, they have to download the HTML document, stylesheets, javascript files and images before being able to use your page. That may be as many as 30 components and 2.4 seconds.",
            "Enabling browser caching lets you store a cached version of your site in a visitor’s browser. This means that when a user returns to your site, it will load faster.",
          ]}
          img={image7}
        />

        <GridArticle
          title="Reduce image sizes"
          array={[
            "For a successful eCommerce site images are absolutely necessary.",
            "That also means image compression is critical. Reducing their size could likely have a big impact on how long the page takes to load.",
            "So we’ll reduce their sizes by cropping them.",
          ]}
          img={image8}
          revers
        />

        <TextArticle size="22" textAlign="center">
          You can see how big of an impact images make on the main page of your
          store.
        </TextArticle>

        <GridArticle
          title="Optimize CSS delivery"
          array={[
            "Your visitors’ browsers only need to download one CSS file instead of multiple, which reduces the overall number of requests and decreases page load time.",
            "We advise you to avoid including CSS in HTML code (like in divs or your headings). We’ll clean your code and put all of your CSS in your external stylesheet. This reduces the size of your code and creates fewer code duplications",
          ]}
          img={image9}
        />
        <GridArticle
          title="Prioritize above-the-fold content (lazy loading)"
          array={[
            "We can improve user experience by having your above the fold (top of the page) section load faster — even if the rest of the page takes a few seconds to load.",
            "This is called lazy loading and is particularly helpful for pages with lots of content below the fold.",
            "For example, that page of your website includes 20 photos. Normally, a user’s browser would need to download all of those images before displaying anything on the page.",
            "With lazy loading, it can load the content within view first, then load all of those photos after.",
          ]}
          img={image10}
          revers
        />
        <GridArticle
          title="Reduce the number of plugins on your site"
          array={[
            "Having too many plugins installed can cause some issues. They can slow your site, create security issues, and even cause crashes and other technical difficulties.",
          ]}
          img={image11}
        />
        <GridArticle
          title="Reduce redirects"
          array={[
            "Redirects are often necessary when you move and delete pages, and are the best way to eliminate issues with broken links.",
            "But having too many of them can create additional HTTP requests, which can negatively impact speed, particularly on mobile devices.",
            "This means it’s best to keep them to a minimum. In fact, Google says that ideally, site owners would eliminate them entirely.",
          ]}
          img={image12}
          revers
        />
        <TextArticle size="22" textAlign="center">
          Тhis is not realistic, but there’s room for improvement.
          <br />
          You can see all of the 301 redirects on your site here:
        </TextArticle>
        <Image paddingRight="0" margin="30px auto" src={image13} />
        <TitleArticle>We could make your website 2-3 times faster</TitleArticle>
      </ArticleContainer>
    </PageContainer>
  );
};

export default TimeIsMoney;
