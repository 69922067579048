import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Giftcoin from '../../../assets/images/portfolio/giftcoin/giftcoin-poster.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const GiftCoinPage = () => {
	return (
		<CaseDetailContainer caseName="GiftCoin">
			<Helmet>
				<title>SourceMates - GiftCoin</title>
			</Helmet>
			<Title text="Crypto gifts platform" size={40} margin="0 0 20px 0" />
			<Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
				<b>THE CHALLENGE</b><br />
				The client contacted us, knowing our relevant expertise in the cryptocurrency sphere. The main challenge of the project: activation of a gift card with a cryptocurrency inside in the service and further use of it.<br />
				<br /><b>Tech stack:</b><br />
				-  React.js<br />
				-  Node.js<br />
				-  Redux<br />
				-  AWS<br />
				-  MongoDB<br />
			</Text><br />
			<Image src={Giftcoin} />
			<Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
				<b>THE SOLUTION</b><br />
				First, we analyzed and created the terms of reference, based on the idea and customer requirements. Subsequently, a prototype was created, then, the design, backend, and frontend parts of the project were developed step by step in coordination with the client. One of the separate stages was integration with the Coinbase cryptocurrency exchange platform.
				<br /><br /><b>RESULTS</b><br />
				The result of this work is a developed,  full-fledged and successful platform for registration and activation of gift cards on the service and the subsequent use of this cryptocurrency, optimized the service with appropriate data encryption.
			</Text>

		</CaseDetailContainer>
	);
};

export default GiftCoinPage;
