import React from "react";
import styled from "styled-components";
import { BlackColor, SecondaryColor } from "../../../../constants/colors";
import Image from "../../../UiKit/Image";
import Text from "../../../UiKit/Text";
import Title from "../../../UiKit/Title";

const Wrapper = styled.div`
	padding: 60px 30px 60px;
	box-shadow: 0 0 15px rgb(0 0 0 / 10%);
`;

const Row = styled.div`
	display: flex;
	justify-content: center;
    	margin-bottom: 20px;
`;

const Divider = styled.div`
    display: block;
	border: 1px solid ${SecondaryColor};
    width: 30px;
    margin: 0 auto 20px;
`
const Img = styled.img`
	display: inline;
	width: 19px;
	height: 18px;
`

const ContactCard = ({ title, icon, info, pl, location, us }) => {
    return (
	 <Wrapper>
	     <Row>
		  <Image src={icon}/>
	     </Row>
	     <Row>
		  <Title text={title} weight={300}/>
	     </Row>
	     <Divider/>
	     <Text textAlign="center" color={`${BlackColor}90`}>{pl &&<Img src={pl}/>} {info}</Text>
		 {location &&  <Text textAlign="center" color={`${BlackColor}90`}>{us &&<Img src={us}/>} {location}</Text>  }
	 </Wrapper>
    );
};

export default ContactCard;
