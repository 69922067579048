import React from "react";
import styled from "styled-components";
import { WhiteColor } from "../../../constants/colors";
import { PageGradient } from "../index";
import Title from "../../UiKit/Title";

const TitleWrapper = styled.div`
	text-align: center;
	padding: 50px 0 70px;
`;

const PageHeader = ({ title, weight }) => {
    return (
	 <PageGradient>
	     <TitleWrapper>
		  <Title text={title} color={WhiteColor} weight={weight} size={60}/>
	     </TitleWrapper>
	 </PageGradient>
    );
};

export default PageHeader;
