import {useState} from 'react';
import {send} from "emailjs-com";


const useApply=() => {
    const [toApply, setToApply] = useState({
    email: "",
    message: "",
    files: ""
}
)
const handleChange = (e) => {
    setToApply({ ...toApply, [e.target.name]: e.target.value });
};


const onSubmit = (e) => {
    e.preventDefault();
    send(
        'service_tlqmryr',
        'template_uz5lfg9',
        toApply,
        'ZgfnkwL92-MTenF0y'
    )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
            console.log('FAILED...', err);
        });
};
return {toApply, handleChange, onSubmit}
};

export default useApply
