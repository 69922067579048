import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import {BackEndDeveloperPage, CareersPage, FrontEndDeveloperPage, MobileDeveloperPage, LeadGenerationPage} from "../pages";

const CasesRouter = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={path} exact component={CareersPage}/>
            <Route path={`${path}/backend`} exact component={BackEndDeveloperPage}/>
            <Route path={`${path}/frontend`} exact component={FrontEndDeveloperPage}/>
            <Route path={`${path}/mobile`} exact component={MobileDeveloperPage}/>
            <Route path={`${path}/lead`} exact component={LeadGenerationPage}/>
        </Switch>
    )
};

export default CasesRouter
