import React from "react";
import styled from "styled-components";
import { SecondaryColor } from "../../../../constants/colors";
import Text from "../../../UiKit/Text";

const Wrapper = styled.div`
	min-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 50px 60px;
`;

const NameBlock = styled.div`
`;

const ReviewBlock = styled.div`
	margin-bottom: 30px;
	position: relative;

	&:before {
		position: absolute;
		content: "“";
		font-size: 40px;
		right: 100%;
		top: -40px;
	}

	&:after {
		position: absolute;
		content: "”";
		font-size: 40px;
		left: 100%;
		bottom: -55px;
	}
`;

const ReviewCard = ({title, text, author, company}) => {
    return (
	 <Wrapper>
	     <ReviewBlock>
		 <Text weight={600} size={18} marginBottom="10px">
		 	{title}		      
		 </Text>
		  <Text weight={400} size={16}>
		      {text}
		  </Text>
	     </ReviewBlock>
	     <NameBlock>
		  <Text size={20}>{author}</Text>
		  <Text size={12} color={SecondaryColor}>{company}</Text>
	     </NameBlock>
	 </Wrapper>
    );
};

export default ReviewCard;
