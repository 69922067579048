import React from "react";
import styled from "styled-components";
import Map from "../../assets/images/map-pattern.png";
import { AnimationContainer } from "../../ui/containers";
import { ChooseReasonCard } from "../../ui/elements";
import Ic from "../../assets/images/service-icon-1.png";
import user from "../../assets/images/icons/user.png"
import { Title } from "../../ui/UiKit";

const Wrapper = styled.div`
	position: relative;
	padding: 100px 0 100px;
`;

const MapPattern = styled.div`
	position: absolute;
	top: 150px;
	left: 0;
	right: 0;
	bottom: 0;
	background-position: center top;
	background-repeat: no-repeat;

	background-image: url("${Map}");
	z-index: -1;
`;

const Content = styled.div`
	max-width: 1210px;
	padding: 0 20px;
	margin: 0 auto;
`;

const CardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
    gap: 30px;
`;

const WhyPeopleChooseUsSection = () => {
    return (
	 <Wrapper>
	     <MapPattern/>
	     <Content>
		  <Title text="Why people from all over the World choose Us" size={40} margin="0 0 50px 0"/>
		  <AnimationContainer side="left" speed={500} startWhenShow>
		  <CardsWrapper>
		      <ChooseReasonCard text='"I am impressed with their availability, level of expertise, and hard work." Callum Brennan. Copenhagen, Denmark' icon={user}/>
		      <ChooseReasonCard text='"They always had an answer and explanation to our questions, they proposed new solutions and improvements." Pavel Tsenedov. Amsterdam, Netherlands' icon={user} position={'flex-end'}/>
		      <ChooseReasonCard text='"Working with Sourcemates has been a breeze for me." Vik Bogdanov. Tallin, Estonia' icon={user}/>
		  </CardsWrapper>
		  </AnimationContainer>
	     </Content>

	 </Wrapper>
    );
};

export default WhyPeopleChooseUsSection;
