import React from "react";
import styled from "styled-components";
import { BlackColor } from "../../../constants/colors";

const StyledButton = styled.button`
	padding: 10px 24px;
	display: inline-block;
	border-radius: 50px;
	color: ${BlackColor};
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 1px;
	background-color: #ffffff;
	outline: none;
	border: none;
	transition: all 0.1s ease-in;
	font-family: Roboto, sans-serif;
	cursor: pointer;

	&:hover {
		color: #ffffff;
		background-color: #ff3869;
	}
`;

const CommonButton = ({ title, onClick }) => <StyledButton onClick={onClick}>{title}</StyledButton>;

export default CommonButton;
