import { keyframes } from "styled-components";

export const AppearanceFromTop = keyframes`
	from {
		opacity: 0;
		transform: translateY(-80px);
	}
	to {
		opacity: 1;
		transform: translateY(0);

	}
`;

export const AppearanceFromBottom = keyframes`
	from {
		opacity: 0;
		transform: translateY(80px);
	}
	to {
		opacity: 1;
		transform: translateY(0);

	}
`;

export const AppearanceFromLeft = keyframes`
	from {
		opacity: 0;
		transform: translateX(-80px);

	}
	to {
		opacity: 1;
		transform: translateX(0);

	}
`;

export const AppearanceFromRight = keyframes`
	from {
		opacity: 0;
		transform: translateX(80px);
	}
	to {
		opacity: 1;
		transform: translateX(0);

	}
`;

export const Rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
`;

export const FloatLeftRight = keyframes`
	0% {
		transform: translateX(0px);
	}

	50% {
		transform: translateX(40px);
	}

	100% {
		transform: translateX(0);
	}
`;

export const FloatUpDown = keyframes`
	0% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(-70px);
	}

	100% {
		transform: translateY(0px);
	}
`;
