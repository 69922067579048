import React from "react";
import { Helmet } from "react-helmet";
import { PageContainer } from "../../ui/containers";
import { PageHeader } from "../../ui/elements";
import MainInfoSection from "./MainInfoSection";
import OurCompetenceSection from "./OurCompetenceSection";
import StatisticSection from "./StatisticSection";
import WhyPeopleChooseUsSection from "./WhyPeopleChooseUsSection";


const AboutUsPage = () => {
    return (
	 <PageContainer>
	     <Helmet>
		  <title>SourceMates - About Us</title>
	     </Helmet>
	     <PageHeader title="About Us"/>
	     <MainInfoSection/>
	     <OurCompetenceSection/>
	     <WhyPeopleChooseUsSection/>
	     <StatisticSection/>
	 </PageContainer>
    );
};

export default AboutUsPage;
