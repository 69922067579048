import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { AnimationContainer, PageContainer } from "../../ui/containers";
import { JobsCard, PageHeader } from "../../ui/elements";
import { device } from "../../utils/devices";
import vacancy_1 from "../../assets/images/careers/vacancy_1.jpg";
import vacancy_2 from "../../assets/images/careers/vacancy_2.jpg";
import vacancy_3 from "../../assets/images/careers/vacancy_3.jpg";

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  gap: 2.5rem;
  padding: 30px;
  max-width: 1300px;
  margin: auto;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CareersPage = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>SourceMates - Careers</title>
      </Helmet>
      <PageHeader title="Our friendly team of professionals is missing you!" />
      <AnimationContainer side="left" speed={500}>
        <Content classname="content">
          <JobsCard
            img={vacancy_1}
            title="Back End developer"
            stack={["NodeJS", "Typescript"]}
            description="Our mission is to create unprecedented software solutions that drive a global change, now we are opening a new project and again looking for a Node.js developer."
            link="/backend"
          />

          <JobsCard
            img={vacancy_2}
            title="Front End developer"
            link="/frontend"
            stack={["React", "Redux", "JavaScript"]}
            description="We are looking for a front-end developer who loves and knows how to use React.
                              We work with projects in various domains: logistics, art, fintech, medicine and others."
          />

          <JobsCard
            img={vacancy_2}
            img={vacancy_3}
            title="Lead Generation Specialist"
            link="/lead"
            stack={["English (B2-C1)", "Communication skills"]}
            description="Generate potential clients through different outreach channels ( LinkedIn mostly). 
            Search for relevant jobs on UpWork according to the Ideal Client Profile"
          />
          {/* <JobsCard img={vacancy_3}  title="Mobile developer"
                              link="/mobile" stack={["React", "React Native"]}
                              description="Excepteur sint ocacat cupidatat
                     non proi dent sunt in culpa qui officia deserunt.
                      mollit anim id est laborum. sed ut pers piciatis
                      unde omnis iste natus error. sit voluptatem."/> */}
        </Content>
      </AnimationContainer>
    </PageContainer>
  );
};

export default CareersPage;
