import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import LakeStudio from '../../../assets/images/portfolio/lamaron/2.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const LamaronPage = () => {
    return (
        <CaseDetailContainer caseName="Lamaron">
            <Helmet>
                <title>SourceMates - Lamaron</title>
            </Helmet>
            <Title text="Lamaron" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>THE CHALLENGE</b><br />
                One of our customers advised our services to his partners with the emphasis on the fact that the engineers of our company have professional expertise in the services' development of varying complexity, so the customer turned to us in order to change the web design part, as well as to develop a large admin part with the management of user service functionality.
                <br /><br /> <b>Tech stack:</b><br />
                - php<br />
                - Html5<br />
                - Css3/SaSS<br />
                - Bootstap4<br />
                - Wordpress<br /><br />
            </Text>
            <Image src={LakeStudio} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b> THE SOLUTION</b><br />
                While working on this, our developers offered solutions that fit the specific business of the client. We also expanded the functionality and developed from a scratch personal account, admin panel, as well as changing the design with the development of appropriate logic of the service.
                <br /><br /><b> RESULTS</b><br />
                Managers of the client company found it easier and more pleasant to work with the new functionality, design and automation of many routine tasks, allowing them to focus on communication with customers and increasing sales (15% by the first three months)
            </Text>

        </CaseDetailContainer>
    );
};

export default LamaronPage;
