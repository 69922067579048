import React from "react";
import { Helmet } from "react-helmet";
import { JobsDetailContainer} from "../../../ui/containers";
import {Button, Text, Title} from "../../../ui/UiKit";
//import StackBadge from "../../ui/elements/StackBadge";
import styled from "styled-components";
import {Modal, StackBadge} from "../../../ui/elements";
import useModal from "../../../utils/useModal";
import useApply from "../../../utils/useApply";

const StackWrapper = styled.div`
	display: flex;
	gap: 10px;  
	flex-wrap: wrap;
	margin-top: 40px;
	margin-bottom: 34px;
`;

const MobileDeveloperPage = () => {
    const {toggle, visible} = useModal();
    const {toApply, onSubmit, handleChange} =useApply();
    return (
        <JobsDetailContainer caseName="Mobile">
            <Helmet>
                <title>Mobile developer</title>
            </Helmet>
            <Title text="Mobile developer" size={44} margin="0 0 20px 0"/>
            <Title text="Payment: By agreemenet" size={28} margin="30px 0 40px 0" />
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="0 0 20px 0">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum.sed perspiciatis unde omnis natus error sit voluptatem accusantium doloremqe lauda ntium totam
                rem aperiam.eaque quae ab illo inventore veritatis quasi architebeatae. vitae dicta sunt explicabo nemo
                enim ipsam voluptatem quia voluptassit aspdernatur aut odit aut fugit, sed quia consequuntur magni
                dolores eos qui ratione voluptatem sequi nesciunt.</Text>
            <Title text="Stack" size={28} margin="30px 0 40px 0" />
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">React JS</Text>
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">React Native</Text>
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">Redux</Text>
                <StackWrapper>
                    <StackBadge item="React"/>
                    <StackBadge item="Redux"/>
                    <StackBadge item="React Native"/>
                </StackWrapper>
            <Button title="Respond to vacancy"  onClick={toggle}/>
            <Modal visible={visible} toggle={toggle} handleChange={handleChange} onSubmit={onSubmit} toApply={toApply}/>
        </JobsDetailContainer>

    );
};

export default MobileDeveloperPage;
