import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import InterService from '../../../assets/images/portfolio/interservice-marine/interservice-marine-card-big.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const InterServiceMarinePage = () => {
    return (
        <CaseDetailContainer caseName="InterService Marine">
            <Helmet>
                <title>SourceMates - InterService Marine</title>
            </Helmet>
            <Title text="InterService Marine" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>The challenge</b> <br />
                Our team has known the client of this company for a long time: the client had a business site, which needed various corrections. After the test work, we agreed on the creation of a website to represent and advertise the company with the possibility of booking various services.
                <br /><br />Tech stack:<br />
                - php<br />
                - Html5<br />
                - Css3/SaSS<br />
                - Bootstap4<br />
                - Wordpress<br /><br />
            </Text>
            <Image src={InterService} />

            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                THE SOLUTION<br />
                The main feature in the creation of this service is a clear interaction with the customer on all matters of service implementation, content, optimization and SEO.
                <br /><br />RESULTS<br />
                Our company has created a service that offers maritime services on the coasts of the Baltic, North and Barents Seas, carries out work on repair, verification and maintenance of ships. The site has also increased recognition of the company in the region, increased confidence and, of course, generated profits.
            </Text>

        </CaseDetailContainer>
    );
};

export default InterServiceMarinePage;
