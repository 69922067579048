import React from "react";
import { Helmet } from "react-helmet";
import { JobsDetailContainer } from "../../../ui/containers";
import { Button, Text, Title } from "../../../ui/UiKit";
import styled from "styled-components";
import { Modal, StackBadge } from "../../../ui/elements";
import useModal from "../../../utils/useModal";
import useApply from "../../../utils/useApply";

const StackWrapper = styled.div`
	display: flex;
	gap: 10px;  
	flex-wrap: wrap;
	margin-top: 40px;
	margin-bottom: 34px;
`;



const BackEndDeveloperPage = () => {
    const { toggle, visible } = useModal();
    const { toApply, onSubmit, handleChange } = useApply();
    return (
        <JobsDetailContainer caseName="BackEnd">
            <Helmet>
                <title>Back End developer</title>
            </Helmet>
            <Title text="Back End developer" size={40} margin="0 0 20px 0" />
            <Title text="Payment: By agreement" size={28} margin="30px 0 40px 0" />
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="0 0 20px 0">

                Backend Developer Position - Node.js / Typescript<br /><br />

                What will you work with?<br />
                We work with projects in various domains: logistics, art, fintech, medicine and others. Basically, our customers are clients of various industries from the EU and the USA.<br /><br />

                How to understand that this position is for you?<br />
                • You like to automate processes and watch how your result makes people happier<br />
                • You like when your job changes people's lives for the better<br />
                • Want and know how to share your point of view for solving complex technical problems<br />
                • You are not afraid of and love challenging tasks<br />
                • You are a proactive  person and like to suggest ideas for improving existing processes<br />
                • You understand that your skills are good, but there is still enough space for improvement<br />
                • You are persistent and succeed despite difficulties<br />
                • You would like to work in a friendly team and gladly go to work<br />
                • You would like to be able to work remotely or in the office<br />
                • You like coffee and cookies at work and a lot of different activities such as Pizza Fridays, sports events and fun corporate parties<br /><br />

                What do we expect from you?<br />
                • Ability and desire to work with Node.js (experience from 2 years)<br />
                • Stories about your most difficult projects in which you participated and took an important role and / or commercial development experience of at least 3 years<br />
                • Desire to work in a team of experienced professionals<br />
                • Ability to use Git<br />
                • Responsibility in working with tasks<br />
                • Ability to write REST API with closed eyes<br />
                • Know how to "deploy" and are able to do it yourself<br />
                • Knowledge of patterns and understanding of their featuresv
                • English from intermediate (B1 and above)<br />
                • Positive attitude in communications<br /><br />

                What would be great?<br />
                • Experience with Typescript<br />
                • Experience and knowledge of the features of the Frontend<br />
                • Experience with GraphQL<br />
                • Experience with microservices<br />
                • Experience with Google Cloud, AWS or other cloud platforms<br />
                • You are at the Keep Learning stage here

            </Text>
            <Title text="Stack" size={28} margin="30px 0 40px 0" />
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">Node JS</Text>
            <Text size={16} lineHeight={1.8} opacity={0.7} margin="20px 0">Typescript</Text>
            <StackWrapper>
                <StackBadge item="Node JS" />
                <StackBadge item="Typescript" />
            </StackWrapper>
            <Button title="Respond to vacancy" onClick={toggle} />
            <Modal visible={visible} toggle={toggle} handleChange={handleChange} onSubmit={onSubmit} toApply={toApply} />
        </JobsDetailContainer>

    );
};

export default BackEndDeveloperPage;
