import React from "react";
import styled from "styled-components";
import { WhiteColor } from "../../../../constants/colors";
import Image from "../../../UiKit/Image";
import Text from "../../../UiKit/Text";

const Wrapper = styled.div`
	padding: 30px 30px;
	background-color: ${WhiteColor};
	box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
	transition: all 300ms ease;
	width: fit-content;
	display: flex;
	align-items: center;
    align-self: ${({position}) => position || 'unset'};

	&:hover {
		box-shadow: 0 0 25px rgb(0 0 0 / 20%);
	}

`;

const ChooseReasonCard = ({ text, icon, position }) => {
    return (
	 <Wrapper position={position}>
	     <Image src={icon} height={'60px'}/>
	     <Text>{text}</Text>
	 </Wrapper>
    );
};

export default ChooseReasonCard;
