import React from "react";
import styled from "styled-components";
import {device} from "../../../utils/devices";
import { ArticleColor } from "../../../constants/colors";

const Wrapper = styled.h2`
	display: ${({ display }) => display || "block"};
	font-family: Dosis, 'sans-serif';
	font-size: ${({ size }) => size || 40}px;
	color: ${({ color }) => color || ArticleColor};
	font-weight: ${({ weight }) => weight || 600};
	padding: ${({ padding }) => padding || 0};
	margin-top: ${({ marginTop }) => marginTop || 60}px;
	margin-bottom: ${({ marginBottom }) => marginBottom || 30}px;
	text-align: ${({ textAlign }) => textAlign || "center"};
	line-height: ${({ lineHeight }) => lineHeight || 1.3};	
	@media (max-width: 576px) {
		font-size: 28px;
		margin-bottom:15px
	  }
`;

const TitleArticle = ({ children, ...styles }) => {
    return (
	 <Wrapper {...styles}>
	     {children}
	 </Wrapper>
    );
};

export default TitleArticle;
