import React from "react";
import { PageContainer } from "../../ui/containers";
import AchievementSection from "./AchievementSection";

import MainSection from "./MainSection";
import OfferSection from "./OfferSection";
import ReviewSection from "./ReviewSection";
import TechnologiesSection from "./TechnologiesSection";

const MainPage = () => {
    return (
	 <PageContainer>
	     <MainSection/>
	     <OfferSection/>
	     <AchievementSection/>
	     <TechnologiesSection/>
	     <ReviewSection/>
	 </PageContainer>
    );
};

export default MainPage;
