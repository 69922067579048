import React from "react";
import InlineSVG from "react-inlinesvg";

const Icon = ({ icon, color, size = 40 }) => {
    return (
	 <InlineSVG src={icon} style={{ fill: color, width: `${size}px`, height: `${size}px` }}/>
    );
};

export default Icon;
