import React from "react";
import styled from "styled-components";
import { BlackColor, SecondaryColor, SecondWhiteColor, WhiteColor } from "../../constants/colors";
import { ReviewsSlider } from "../../ui/components";
import { DecorativeSpinner } from "../../ui/elements";
import ReviewImage from "../../assets/images/review.jpg";
import { Text } from "../../ui/UiKit";
import {device} from "../../utils/devices";

const Wrapper = styled.div`
	position: relative;
	padding: 160px 0 60px;
`;

const Content = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	@media ${device.tablet} {
		grid-template-columns: 1fr 1fr;
	}
`;

const ImageBlock = styled.div`
	background-image: url("${ReviewImage}");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const DecorativeSpinnerWrapper = styled.div`
	position: absolute;
	right: 60px;
	z-index: 5;
	@media only screen and (max-width: 768px) {
		top: 40px;
	}
`;

const SliderWrapper = styled.div`
	height: fit-content;
	align-self: end;
	position: relative;
	background-color: ${SecondWhiteColor};
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: 100px;
`;

// const ClutchContainer = styled.div`
// 	display: flex;
// 	align-self: flex-end;
// 	position: sticky;
// 	right: 20px;
// 	bottom: 20px;
// 	background-color: ${BlackColor};
// 	width: fit-content;
// 	margin-top: 1.15rem;
// 	padding: 10px;
// 	cursor: pointer;
//
// 	&:hover {
// 		span {
// 			transition: 0.2s;
// 			color: ${SecondaryColor}
// 		}
// 	}
// `;

const ReviewSection = () => {
    return (
	 <Wrapper>
	     <DecorativeSpinnerWrapper>
		  <DecorativeSpinner/>
	     </DecorativeSpinnerWrapper>
	     <Content>
		  <ImageBlock/>
		  <SliderWrapper>
		      <ReviewsSlider/>
		      {/*<ClutchContainer onClick={() => window.open('https://clutch.co/profile/sourcemates?utm_source=widget&utm_medium=1&utm_campaign=widget&utm_content=num_reviews#reviews')}>*/}
			  {/* <Text color={WhiteColor} size={16} textAlign="center">Our Clutch</Text>*/}
		      {/*</ClutchContainer>*/}
		  </SliderWrapper>
	     </Content>

	 </Wrapper>
    );
};

export default ReviewSection;
