import React from "react";
import styled from "styled-components";
import { SecondaryColor, WhiteColor } from "../../../constants/colors";

const StyledButton = styled.button`
	margin-top: 15px;
	padding: ${({ padding }) => padding || "15px 42px"};
	position: relative;
	display: inline-block;
	font-size: ${({ fontSize }) => fontSize || "17px"};
	line-height: 30px;
	color: ${WhiteColor};
	font-weight: 300;
	overflow: hidden;
	letter-spacing: 1px;
	border-radius: 50px;
	background: none;
	z-index: 1;
	text-transform: capitalize;
	font-family: Roboto, sans-serif;
	transition: all 0.3s ease;
	outline: none;
	cursor: pointer;
	border: none;


	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		transition: all 0.3s;
		border-top-width: 1px;
		border-bottom-width: 1px;
		border-top-style: solid;
		border-bottom-style: solid;
		border-top-color: ${SecondaryColor};
		border-bottom-color: ${SecondaryColor};
		transform: scale(0.1, 1);
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: all 0.3s;
		background: ${SecondaryColor};
	}

	&:hover {
		border-radius: 0;
		color: #ff3869;

		&:before {
			opacity: 1;
			transform: scale(1, 1);
		}

		&:after {
			opacity: 0;
			transform: scale(0.1, 1);

		}
	}
`;

const Button = ({ title, onClick, padding, fontSize }) => <StyledButton onClick={onClick}
										  padding={padding}
										  fontSize={fontSize}>{title}</StyledButton>;

export default Button;
