import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import { ReviewCard } from "../../elements";

const Wrapper = styled.div`
  align-self: flex-end;
`;

const ReviewsSlider = () => {
  return (
    <Wrapper>
      <Carousel
        axis="vertical"
        interval={5000}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        centerMode
        autoPlay
        infiniteLoop
      >
        <ReviewCard
          title="Working with Sourcemates has been a breeze for me"
          text="Sourcemates provides development support, helping redesign an existing website and produce a mobile-friendly version. They do front- and backend work on the platform and work to create AMPs. The work has been delivered on time and continuously satisfies requirements. Sourcemates' positive attitude and eager approach make them a great partner. The engagement continues thanks to the strong value and quality work they produce. "
          author="Vik Bogdanov"
          company="CMO, 8allocate, Estonia"
        />
        <ReviewCard
          title="They always had an answer and explanation to our questions, they proposed new solutions and improvements."
          text="Sourcemates provides ongoing IT services for a micromobility startup. They are tasked to develop back-end solutions to improve fleet positioning. Thanks to their dedication, Sourcemates has done good work so far. In addition to being responsive, they complete tasks accurately and on time. Additionally, throughout the partnership, they offer solutions and improvements for the success of the project."
          author="Pavel Tsenedov"
          company="Head of Strategy, Micromobility Startup, Netherlands"
        />
        <ReviewCard
          title="The entire working process was interesting to me."
          text="Sourcemates was hired by a marine service provider for web development services. The team was tasked to generate a user-friendly online platform that aims to advertise all services provided by the company. The website was successfully launched upon completion, thanks to the impressive communication and implementation skills of Sourcemates. The site was further optimized to ensure that it is highly accessible to users. The client recommended the team to its partners for their impeccable workflow."
          author="Dmitriy Vichinskiy"
          company="CEO, Interservice-Marine , Germany"
        />
        <ReviewCard
          title="Their tech department's set up to a high standard."
          text="Sourcemates effectively demonstrated that their tech department operates at a high standard. Their
		      project manager was a great communicator who ensured clarity at all steps of the process. The
		      experienced developers even took the time to suggest improvements outside of the immediate scope of
		      work."
          author="Evgeny Mahnach"
          company="CTO, AMS LLC"
        />
      </Carousel>
    </Wrapper>
  );
};

export default ReviewsSlider;
