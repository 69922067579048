import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { AnimationContainer, PageContainer } from '../../ui/containers'
import {
  DecorateTextureBlock,
  DecorativeSpinner,
  PageHeader,
  TeammateCard,
} from '../../ui/elements'

// import GregoryS from '../../assets/images/teammates/oit1.jpg'
import EugenS from '../../assets/images/teammates/oit2.jpg'
import AlesiaG from '../../assets/images/teammates/oit30.jpg'

import StasP from '../../assets/images/teammates/oit21.jpg'
import LeonidK from '../../assets/images/teammates/oit28.jpg'

import NazarK from '../../assets/images/teammates/oit6.jpg'
import AntonM from '../../assets/images/teammates/oit16.jpg'
import VasiliyK from '../../assets/images/teammates/oit12.jpg'
import VladislavR from '../../assets/images/teammates/oit25.jpg'
import DenisS from '../../assets/images/teammates/oit22.jpg'
import MihailV from '../../assets/images/teammates/oit20.jpg'
import AndreyN from '../../assets/images/teammates/oit27.jpg'
import DzmitryZ from '../../assets/images/teammates/oit29.jpg'
import DmitryB from '../../assets/images/teammates/oit32.jpg'
import DmitryZ from '../../assets/images/teammates/oit34.jpg'
import MaximM from '../../assets/images/teammates/oit31.jpg'
import AnnaS from '../../assets/images/teammates/oit33.jpg'
import { Title } from '../../ui/UiKit'
import { device } from '../../utils/devices'

const Wrapper = styled.div`
  position: relative;
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
`

const RolesBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-bottom: 1.875rem;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  right: -150px;
`

const OurTeamPage = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>SourceMates - Our Team</title>
      </Helmet>
      <PageHeader title="Our Team" />
      <DecorateTextureBlock top={1700} />
      <Wrapper>
        <AnimationContainer side={'left'} speed={500}>
          <Title
            text="Management"
            size={35}
            textAlign="center"
            margin="30px 0"
          />
          <RolesBlock>
            <TeammateCard
              photo={EugenS}
              name="Eugene Semenets"
              role="Project delivery manager"
            />
            {/*<TeammateCard photo={GregoryS} name="Gregory Strakhov" role="CTO" />*/}
            <TeammateCard
              photo={AlesiaG}
              name="Alesia Grin"
              role="Project Manager"
            />
          </RolesBlock>
        </AnimationContainer>
        <AnimationContainer side={'left'} speed={500}>
          <SpinnerWrapper>
            <DecorativeSpinner />
          </SpinnerWrapper>

          <Title
            text="Sales Department"
            size={35}
            textAlign="center"
            margin="30px 0"
          />
          <RolesBlock>
            <TeammateCard
              photo={StasP}
              name="Stas Pavlovich"
              role="Business Development Managerr"
            />
            <TeammateCard
              photo={LeonidK}
              name="Leonid Kochetcov"
              role="Business Development Manager"
            />
          </RolesBlock>
        </AnimationContainer>
        <AnimationContainer side={'left'} speed={500}>
          <Title
            text="Developers"
            size={35}
            textAlign="center"
            margin="30px 0"
          />
          <RolesBlock>
            <TeammateCard
              photo={NazarK}
              name="Nazar Kuksov"
              role="Software Developer"
            />
            <TeammateCard
              photo={MihailV}
              name="Mihail Vorobeychikov"
              role="Software Developer"
            />
            <TeammateCard
              photo={VladislavR}
              name="Vladislav Rakitski"
              role="Software Developer"
            />
            <TeammateCard
              photo={AntonM}
              name="Anton Meleshkin"
              role="Software Developer"
            />
            <TeammateCard
              photo={DzmitryZ}
              name="Dmitry Zubakin"
              role="Software Developer"
            />
            <TeammateCard
              photo={VasiliyK}
              name="Vasiliy Kashchei"
              role="Software Developer"
            />
            <TeammateCard
              photo={DmitryB}
              name="Dmitry Bykovski"
              role="Software Developer"
            />
            <TeammateCard
              photo={MaximM}
              name="Maxim Martynchuk"
              role="Software Developer"
            />
            <TeammateCard
              photo={DenisS}
              name="Denis Sushkevich"
              role="Software Developer"
            />
            <TeammateCard
              photo={AndreyN}
              name="Andrey Nosenko"
              role="Software Developer"
            />
            <TeammateCard
              photo={DmitryZ}
              name="Dmitry Zinovich"
              role="Designer"
            />
            <TeammateCard photo={AnnaS} name="Anna Semenenko" role="QA" />
          </RolesBlock>
        </AnimationContainer>
      </Wrapper>
    </PageContainer>
  )
}

export default OurTeamPage
