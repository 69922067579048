import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { PageContainer } from "../../ui/containers";
import { ArticleCard, PageHeader } from "../../ui/elements";
import MockPhoto from "../../assets/images/review.jpg";
import CRMPhoto from "../../assets/images/Articles/crm.webp";
import TimePhoto from "../../assets/images/Articles/time.webp";
import { device } from "../../utils/devices";

const Content = styled.div`
  max-width: 1210px;
  padding: 2.5rem 1.25rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const ArticlesPage = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>SourceMates - Articles</title>
      </Helmet>
      <PageHeader title="Articles" />
      <Content>
        <ArticleCard
          img={CRMPhoto}
          title="Creating Your Own CRM"
          description="Salesforce and Apptivo, HubSpot and Zoho – if those names are familiar to you, that probably means that you’ve already experienced the work with some ready-to-use CRM software. It is quite challenging to adapt their features for your particular needs, right? "
          link="articles/art1"
        />
        <ArticleCard
          img={TimePhoto}
          title="Time is money"
          description="We could do much more to help you to get more profit out of your eCommerce. To ensure that, let's start from the very beginning, let’s make your eCommerce store faster! We can help you get more returning customers and lower bounce rate by speeding up."
          link="articles/art2"
        />
       
      </Content>
    </PageContainer>
  );
};

export default ArticlesPage;
