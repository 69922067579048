import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Stanmar from '../../../assets/images/portfolio/stanmar/stanmar-card2.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const StanmarPage = () => {
    return (
        <CaseDetailContainer caseName="Stanmar">
            <Helmet>
                <title>SourceMates - Stanmar</title>
            </Helmet>
            <Title text="Stanmar" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>THE CHALLENGE</b><br />
                Stanmar products are among the most technologically advanced, reliable, and functional in the world, so helping leading companies in various fields is very important for us.
                The idea for the service to promote the products of the famous Stanmar brand was the fast-growing demand for such high-tech machines and presses.
                <br /><br />
                <b>Tech stack:</b><br />
                -React.js<br />
                -Node.js<br />
                - Redux<br />
                - Postgress<br /><br />
            </Text>
            <Image src={Stanmar} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b> THE SOLUTION</b><br />
                While communicating with the customer, our company performed a professional analysis in the field of sales of this kind of machines and technological solutions. After creating a prototype of the service for advertising these kinds of products, the engineers of our company made some suggestions on creating this service to make the usability very convenient.
                <br /><br /><b>RESULTS</b><br />
                Due to the dramatically increased demand and a large number of lost leads, we had a short deadline to finish the project in perfect condition. We did well: the site brought more loyal customers from different countries, the average time on the site has increased several times as well as the average deal size.

            </Text>

        </CaseDetailContainer>
    );
};

export default StanmarPage;
