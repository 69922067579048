import React from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
	display: block;
	width: 100%;
	line-height: 22px;
	padding: 20px 25px;
	color: #222222;
	height: 150px;
	background: #ffffff;
	resize: none;
	font-size: 15px;
	border-radius: 20px;
	border: none;
	outline: none;
	font-family: Arial,serif;
	transition: all 300ms ease;
	box-shadow: 0 0 10px rgb(0 0 0 / 10%);
`

const TextArea = ({value, onChange, placeholder, name, type}) => <StyledTextArea value={value} onChange={onChange}
									      placeholder={placeholder} name={name} type={type}/>

export default TextArea;
