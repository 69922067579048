import React from "react";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import styled from "styled-components";

const Map = ({ width, height, padding, margin }) => {
    return (
	 <MapContainer center={[52.22903170987484, 21.01364238298082]} zoom={15} scrollWheelZoom={false}
			 style={{ width,padding, margin, height, zIndex: 1, borderRadius: 20  }}>
	     <TileLayer
		  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
		  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
	     />
	     <Marker position={[52.22903170987484, 21.01364238298082]}>
		  <Popup>
		      We are here!
		  </Popup>
	     </Marker>
	 </MapContainer>
    );
};

export default Map;
