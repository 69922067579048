import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Tripyacht from '../../../assets/images/portfolio/tripyacht/2.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const TripyachtPage = () => {
    return (
        <CaseDetailContainer caseName="Tripyacht">
            <Helmet>
                <title>SourceMates - FightclubPage</title>
            </Helmet>
            <Title text="Tripyacht" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
               <b> THE CHALLENGE</b><br />
                The client, after a successfully developed corporate website, turned to us again to create a booking service for yacht rentals and other related services.
                <br /><br /><b> Tech Stack:</b><br />
                React.js<br />
                PHP<br />
                Typescript<br /><br />
            </Text>
            <Image border="1px solid #e5e5e5"  src={Tripyacht} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b> THE SOLUTION</b><br />
                Since our developers already have experience in building systems for booking, we have successfully coped with the basic functionality and offered to create a connection to CRM, add chat, calendar, and calculator functions to check the cost of ordered services.<br /><br />
                <b>   RESULTS</b><br />
                The result is a successful platform in the EU markets, the number of orders and visits to the site has increased by 60 percent, and managers have noted simplification of routine work.
            </Text>

        </CaseDetailContainer>
    );
};

export default TripyachtPage;
