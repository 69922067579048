import React from "react";
import styled from "styled-components";
//import Ic from "../../../../assets/images/service-icon-1.png";
import { SecondaryColor, WhiteColor } from "../../../../constants/colors";
import { Image, Text, Title } from "../../../UiKit";

const Wrapper = styled.div`
	position: relative;
	padding: 50px 30px 50px;
	transition: all 0.5s ease;
	background-color: ${WhiteColor};
	z-index: 1;

	&::before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 0;
		transition: all 600ms ease;
		z-index: -1;
	}

	&:hover {
		box-shadow: 0 0 20px rgb(0 0 0 / 15%);

		span {
			color: ${WhiteColor} !important;
			transition: all 600ms ease;
		}

		&::before {
			height: 100%;
			background-color: ${SecondaryColor};
		}
	}
`;

const ContentRow = styled.div`
	display: flex;
	justify-content: center;
	margin: 25px 0;
`;

const OfferCard = ({text,item,img}) => {
    return (
	 <Wrapper>
	     <ContentRow>
		  <Image src={img}/>
	     </ContentRow>
	     <ContentRow>
		  <Title text={text}/>
	     </ContentRow>
	     <ContentRow>
		  <Text
		      textAlign="center"
		      opacity={0.7}
		  >
		      {item}
		  </Text>
	     </ContentRow>
	 </Wrapper>
    );
};

export default OfferCard;
