import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import {
    AppearanceFromBottom,
    AppearanceFromLeft,
    AppearanceFromRight,
    AppearanceFromTop
} from "../../../utils/animations";

const Wrapper = styled.div`
	opacity: ${({ elementIsShowed }) => elementIsShowed ? 1 : 0};
	animation: ${({ side }) => {
		switch ( side ) {
			case "top":
				return AppearanceFromTop;
			case "bottom":
				return AppearanceFromBottom;
			case "left":
				return AppearanceFromLeft;
			case "right":
				return AppearanceFromRight;
			default:
				return "translate(0)";
		}
	}} ${({ speed }) => speed || 1000}ms ease-in;

`;

// Side => top | bottom | left | right

const AnimationContainer = ({ children, side, speed, delay, startWhenShow }) => {
    const [elementIsShowed, setElementIsShowed] = useState( false );
    const element = useRef( null );

    const onScroll = () => {
	 if ( !element.current ) return;

	 const el = element.current.getBoundingClientRect();
	 const top = el.top;
	 const bottom = el.bottom;
	 const height = el.height;
	 if ( elementIsShowed === false && ( ( top + height >= 0 ) && ( height + window.innerHeight >= bottom ) ) ) {
	     setElementIsShowed( true );
	 }
    };

    const startWithDelay = () => setElementIsShowed( true );

    useEffect( () => {

	 if ( startWhenShow ) {
	     window.addEventListener( "scroll", onScroll );

	     return () => window.removeEventListener( "scroll", onScroll );
	 }

	 if ( !!delay ) {
	     setTimeout( startWithDelay, delay );
	     return;
	 }

	 setElementIsShowed( true );

    }, [] );

    return (
	 <Wrapper ref={element} side={elementIsShowed && side} speed={speed} elementIsShowed={elementIsShowed}>
	     {children}
	 </Wrapper>
    );
};

export default AnimationContainer;
