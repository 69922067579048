import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	max-width: 1200px;
	padding: 0 20px;
	margin: auto;
`;

const ArticleContainer = ({ children }) => {
    return (
	 <Wrapper>
	     {children}
	 </Wrapper>
    );
};

export default ArticleContainer;
