import React from "react";
import styled from "styled-components";
import { PrimaryLighterColor } from "../../constants/colors";
import Laptop from "../../assets/images/laptop.png";
import { AnimationContainer } from "../../ui/containers";
import { DecorateTextureBlock, DecorativeSpinner } from "../../ui/elements";
import { Image, Text, Title } from "../../ui/UiKit";

const Wrapper = styled.div`
  position: relative;
  padding: 140px 0 60px;

  &:before {
    position: absolute;
    content: "";
    right: 0;
    top: 100px;
    height: 200px;
    width: 430px;
    background-color: ${PrimaryLighterColor};
    z-index: -1;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
`;

const TextWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  align-self: flex-start;
  @media (max-width: 768px) {
    padding-top: 2rem;
  }
`;

const AchievementSection = () => {
  return (
    <Wrapper>
      <DecorateTextureBlock top={-200} />
      <Content>
        <TextWrapper>
          <DecorativeSpinner margin="0 0 30px 20px" />
          <Title
              text="We are the best company"
              size={42}
              margin="0 0 0 0"
              />
          <Title
            text="and our clients know it."
            size={42}
            margin="0 0 20px 0"
          />
          <Text size={17} weight={400}>
            For over 3 years we have been creating mobile and web applications
            that help our customers increase profits, reduce costs and optimize
            business processes.
          </Text>
          <Text size={17} weight={400}>
            We are able to carry out the entire cycle of work: from collecting
            requirements and prototyping to publishing and support.
          </Text>
        </TextWrapper>
        <div>
          <AnimationContainer side={"right"} speed={500} startWhenShow>
            <Image src={Laptop} maxWidth="inherit" />
          </AnimationContainer>
        </div>
      </Content>
    </Wrapper>
  );
};

export default AchievementSection;
