import { useState } from 'react';

const useModal = () => {
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    function toggle() {
        setVisible(!visible);
    }
    return {toggle, visible, name, setName, selectedFile, setSelectedFile}
};


export default useModal;