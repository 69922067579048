import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import {AboutUsPage, ContactUsPage, MainPage, OurTeamPage} from "../pages";
import { Footer, Header } from "../ui/components";
import ArticlesRouter from "./ArticlesRouter";
import CasesRouter from "./CasesRouter";
import CareersRouter from "./CareersRouter";

const MainRouter = () => {
    const location = useLocation();

    useEffect( () => {
	 window.scrollTo( 0, 0 );
    }, [location.pathname] );
    return (
	 <div style={{ overflow: "hidden" }}>
	     <Header/>
	     <Switch>
		  <Route path="/" exact component={MainPage}/>
		  <Route path="/about" exact component={AboutUsPage}/>
		  <Route path="/team" exact component={OurTeamPage}/>
		  <Route path="/careers"  component={CareersRouter}/>
		  <Route path="/contacts" exact component={ContactUsPage}/>
		  <Route path="/cases" component={CasesRouter}/>
		  <Route path="/articles" component={ArticlesRouter}/>
	     </Switch>
	     <Footer/>
	 </div>
    );
};

export default MainRouter;
