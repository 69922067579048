import React from "react";
import styled from "styled-components";
import { BlackColor, PrimaryColor, SecondWhiteColor } from "../../../constants/colors";
import { Icon, Text } from "../../UiKit";
import Logo from "../../../assets/icons/logo.svg";
import FB from "../../../assets/icons/facebook.svg";
import TG from "../../../assets/icons/telegram.svg";
import LD from "../../../assets/icons/linkedin.svg";
import WA from "../../../assets/icons/whatsapp.svg";

const Wrapper = styled.div`
	position: relative;
	padding: 90px 0 60px;
	margin-top: 40px;
    	bottom: -60px;
	@media only screen and (max-width: 540px) {
		display: grid;
		grid-template-columns: 2fr;
		padding: 50px 0 60px;
	}

	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		background-color: ${SecondWhiteColor};
		right: 0;
		bottom: -100px;
		transform: skewY(3deg);
		z-index: -4;
	}
`;

const Content = styled.div`
	max-width: 1210px;
	padding: 0 20px;
	margin: 0 auto;
	@media only screen and (max-width: 540px) {
		
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@media only screen and (max-width: 540px) {
		display: grid;
		align-items: center;
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	@media only screen and (max-width: 540px) {
	justify-content: center;
		align-items: center;
		padding-bottom: 25px;
`;

const CompanyName = styled.span`
	font-family: Dosis, 'sans-serif';
	font-weight: 600;
	font-size: 1.5rem;
	color: ${BlackColor}
`;

const ContactBlock = styled.div`
	padding-left: 0.7rem;
	@media only screen and (max-width: 540px) {
		justify-content: center;
		align-items: center;
	}
`;

const Link = styled.a`
	text-decoration: none;
	color: ${PrimaryColor};
	font-weight: 400;
	font-family: Roboto, "sans-serif";
	font-size: 15px;
`;

const SocialBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-top: 10px;
	@media only screen and (max-width: 768px) {
		padding: 10px;
	}
`;

const SocialLink = styled.a`
	&:hover {
		svg {
			transition: 0.2s;
			fill: ${({ fillColor }) => fillColor};
		}
	}
`;

const Bottom = styled.div`
	padding: 20px 0px;
	border-top: 1px solid #d9d9d9;
`;

const Footer = () => {
    return (
	 <Wrapper>
	     <Content>
		  <Top>
		      <LogoWrapper>
			   <Icon icon={Logo} color={PrimaryColor}/>
			   <CompanyName>SourceMates</CompanyName>
		      </LogoWrapper>
		      <ContactBlock>
			   <Text size={16}>Sales department: <Link
				href="mailto:info@sourcemates.com">info@sourcemates.com</Link></Text>
			   <Text size={16}>31 Nowogrodzka, Warsaw, Poland</Text>
			   <SocialBlock>
				<SocialLink rel="noreferrer" href="tg://resolve?domain=EugeneSource"  fillColor="#55aded">
				    <Icon icon={TG} size={30}/>
				</SocialLink>
				<SocialLink rel="noreferrer" href="https://www.facebook.com/groups/1026867144152006" target="_blank"
					     fillColor="#3b5699">
				    <Icon icon={FB} size={30}/>
				</SocialLink>
				<SocialLink rel="noreferrer" href="https://www.linkedin.com/company/code24-com/" target="_blank"
					     fillColor="#0878b7">
				    <Icon icon={LD} size={30}/>
				</SocialLink>
				<SocialLink rel="noreferrer" href="https://api.whatsapp.com/send?phone=48505640642" target="_blank"
					     fillColor="#25D366">
				    <Icon icon={WA} size={30}/>
				</SocialLink>
			   </SocialBlock>
		      </ContactBlock>
		  </Top>
		  <Bottom>
		      <Text textAlign="center">SourceMates © 2021 All Right Reserved
		      </Text>
		  </Bottom>
	     </Content>
	 </Wrapper>
    );
};

export default Footer;
