import React from "react";
import styled from "styled-components";
import Image from "../../../UiKit/Image";
import Text from "../../../UiKit/Text";

const Wrapper = styled.div`
	width: 350px;
`

const TeammateCard = ({photo, name, role, stack}) => {
    return (
	 <Wrapper>
	     <Image src={photo} width="100%"/>
	     <Text size={25} textAlign="center">{name}</Text>
	     <Text textAlign="center" opacity={0.6}>{role}</Text>
	     {stack && <Text>{stack}</Text>}
	 </Wrapper>
    );
};

export default TeammateCard;
