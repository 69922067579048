import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import LakeStudio from '../../../assets/images/portfolio/lake-studio/2.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const LakeStudioPage = () => {
    return (
        <CaseDetailContainer caseName="LakeStudio">
            <Helmet>
                <title>SourceMates - Lake Studio</title>
            </Helmet>
            <Title text="LakeStudio" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b> The Challenge</b><br/>
                Lake studio is an e-commerce store,  design of which focused on Italian and Japanese fabrics of exceptional quality and precise tailoring. They are putting great emphasis on exclusive prints, collaborating with modern artists, artisanal crafts such as hand-made embroidery and lavish jewelry pieces with exquisite cut, having a unique story behind each collection. The client found us on the Clutch platform and decided to ask for our help to scale his store on Shopify.
                <br/><br/><b>Tech stack:</b><br/>
                - php<br/>
                - Html5<br/>
                - Css3/SaSS<br/>
                - Shopify<br/><br/>
            </Text>
            <Image src={LakeStudio} />

            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b> The Solution</b><br/>
                Our engineers have created this online store on the basis of the online commerce platform Shopify, with a number of functional parts that unavailable for basic users we developed  some functions to increase the loyalty and convenience of customers.
                <br/> <br/><b>Results</b><br/>
                Thanks  to our collaborative work,Lake Studio is one of the top online stores in its field.
            </Text>

        </CaseDetailContainer>
    );
};

export default LakeStudioPage;
