import React from "react";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { Button, Image, Text, Title } from "../../../UiKit";
import StackBadge from "../../StackBadge";

const Wrapper = styled.div`
  box-shadow:  0 0 15px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;  
  flex-grow:1;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
const StackWrapper = styled.div`
	display: flex;
	gap: 0.7rem;  
	flex-wrap: wrap;
	margin-top: 2.5rem;
	margin-bottom: 2.1rem;
`;


const JobsCard = ({ img, title, description, link, stack }) => {
  const history = useHistory();
  return (
    <Wrapper>
      <Image src={img} width="100%" />
      <Content>
        <Title text={title} margin="0 0 20px 0" />
        <Text marginBottom="auto">{description}</Text>
        <StackWrapper>
          {stack && stack.map(i => <StackBadge item={i} key={i} />)}
        </StackWrapper>
        <ButtonWrapper>
          <Button title="Open" padding="0.1875rem 0.9375rem" fontSize="0.875rem" onClick={() => history.push(`${history.location.pathname}${link}`)} />
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};

export default JobsCard;
