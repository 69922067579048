import React from "react";
import styled from "styled-components";
import BG1 from "../../../assets/images/bg-pattern-1.png";
import BG2 from "../../../assets/images/bg-pattern-2.png";

const Wrapper = styled.div`
	width: 100%;
	height: auto;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: -150px;
		background-image: linear-gradient(to right, #07889B 0%, #4B7AB5 100%);		    
		z-index: -2;
	}
`;

const Content = styled.div`
	padding-top: 120px;
`;

const GradientLayer = styled.div`
	background-image: url("${({ src }) => src}");
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: block;
	background-repeat: no-repeat;
	background-position: ${({ position }) => position && position};
`;

const Layers = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;


const PageGradient = ({children}) => {
    return (
	 <Wrapper>
	     <Layers>
		  <GradientLayer src={BG1}/>
		  <GradientLayer src={BG2} position="right top"/>
	     </Layers>
	     <Content>
		  {children}
	     </Content>
	 </Wrapper>
    );
};

export default PageGradient;
