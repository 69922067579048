import React from "react";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { Button, Image, Text, Title } from "../../../UiKit";

const Wrapper = styled.div`
	box-shadow:  0 0 15px rgb(0 0 0 / 10%);	
	display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    padding: 20px;
	height: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
	width: 100%;
    display: flex;
    justify-content: flex-end;
	margin-top: auto;
`

const ArticleCard = ({img, title, description, link}) => {
    const history = useHistory();
    return (
	 <Wrapper>
	     <Image src={img} width="100%" height="360px"/>
	     <Content>
	     <Title text={title} margin="0 0 20px 0"/>
	     <Text>{description}</Text>
		  <ButtonWrapper>
		  <Button title="Read" padding="3px 15px" fontSize="14px" onClick={() => history.push(link)}/>
		  </ButtonWrapper>
		  </Content>
	 </Wrapper>
    );
};

export default ArticleCard;
