import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Sudakov from '../../../assets/images/portfolio/sudakov/sudakov-card2.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const SudakovPage = () => {
    return (
        <CaseDetailContainer caseName="Sudakov">
            <Helmet>
                <title>SourceMates - Sudakov</title>
            </Helmet>
            <Title text="Sudakov" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>THE CHALENGE </b><br />
                Our company is also engaged in creating selling websites for various forms of business. The main task of Sudakov's site is to display the collection of jewelry products, as well as to advertise the creation of exclusive custom-made luxury items.
                <br /><br />  <b>Tech stack:</b><br />
                - Html5<br />
                - Css3/SaSS<br />
                - Bootstap4<br />
                - Wordpress<br /><br />
            </Text>
            <Image src={Sudakov} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b> THE SOLUTION</b><br />
                Our managers carefully negotiated the choice of templates, technology, optimization, implementation of functionality to change the content for the admin panel. The next steps were the site development, testing and release of the project.
                <br /><br />
                <b>RESULTS</b><br />
                Currently, the site provides jewelry creation services, advertises finished products, attracts 36% more new customers, and 22% more profits over the past year.
            </Text>

        </CaseDetailContainer>
    );
};

export default SudakovPage;
