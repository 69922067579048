import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import Janus from '../../../assets/images/portfolio/luckytok/1.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const LuckytokPage = () => {
    return (
        <CaseDetailContainer caseName="Luckytok">
            <Helmet>
                <title>SourceMates - Luckytok</title>
            </Helmet>
            <Title text="Luckytok" size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b>The challenge</b><br />
                Tik Tok is rapidly developing, there are more and more bloggers who are beginning to earn and develop in this social network. And to stay on the wave and not to spend huge budgets on promotion, the developers of our company have developed the idea of creating Luckytok service.
                LuckyTok is a service through which you can promote Tik Tok account, get followers and likes. On the platform after registration, you can set up your promotion settings in a few minutes - and get new subscribers and likes in your account for a long time.
                <br /><br /><b>Tech stack:</b><br />
                - React.js<br />
                - Chart.js<br />
                - Node.js<br />
                - Nginx<br /><br />
            </Text>
            <Image src={Janus} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b>The Solution</b><br />
                Engineers of our company have created a large and successfully functioning promotion service in Tik Tok with convenient and interesting functionality, which in the shortest time can raise to the top your acc that was created several minutes ago.
                <br /><br /><b>Results</b><br />
                An embodied idea from scratch that brings money to the customer - that's our ultimate goal. The service itself will go public in the nearest future.
            </Text><br />
            <center><iframe width="1140" height="515" src="https://www.youtube.com/embed/6DrpxXGwXdQ"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe> </center>
        </CaseDetailContainer>
    );
};

export default LuckytokPage;
