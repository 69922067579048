import React from "react";
import { Helmet } from "react-helmet";
import { CaseDetailContainer } from "../../../ui/containers";
import ThunderIt from '../../../assets/images/portfolio/thunder-it/thunder-it-card.jpg'
import { Image, Text, Title } from "../../../ui/UiKit";

const ThunderITPage = () => {
    return (
        <CaseDetailContainer caseName="ThunderIt">
            <Helmet>
                <title>SourceMates - ThunderIt</title>
            </Helmet>
            <Title text="ThunderIT " size={40} margin="0 0 20px 0" />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="0 0 20px 0">
                <b> The Challenge</b><br />
                We collaborated with ThunderIT as part of a joint project for one client, and then they commissioned us to develop their own product. A streaming platform for fitness and body health was developed for this company. The main work was to create new functionality for the usability of the platform and the interaction of users.
                <br /> <br />  <b>Tech stack:</b><br />
                - php<br />
                - Html5<br />
                - Css3/SaSS<br />
                - Symphony<br /><br />
            </Text>
            <Image src={ThunderIt} />
            <Text size={20} lineHeight={1.8} opacity={0.6} margin="20px 0">
                <b>THE SOLUTION</b><br />
                During our work, we thoroughly communicated with the client to understand his vision of his product and together we created a system of donations, connected the payment systems, and developed the functionality of the client interaction platform based on the streaming engine Wowza.
                <br /><br />   <b>RESULTS</b><br />
                The result is a successful and functioning platform that benefits users and generates money for the creators.
            </Text>

        </CaseDetailContainer>
    );
};

export default ThunderITPage;
