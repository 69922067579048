import React from "react";
import styled from "styled-components";
import { SecondWhiteColor } from "../../constants/colors";
import { AnimationContainer } from "../../ui/containers";
import { OfferCard } from "../../ui/elements";
import { Title } from "../../ui/UiKit";
import {device} from "../../utils/devices";
import web from "../../assets/images/icons/1.png"
import mobile from "../../assets/images/icons/7.png"
import crm from "../../assets/images/icons/2.png"
import crypto from "../../assets/images/icons/8.png"
import commerce from "../../assets/images/icons/4.png"
import app from "../../assets/images/icons/5.png"

const Wrapper = styled.div`
  position: relative;
  padding: 140px 0 60px;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background-color: ${SecondWhiteColor};
    right: 0;
    bottom: 0;
    transform: skewY(2deg);
    z-index: -4;
  }
`;

const Content = styled.div`
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr   ;
  }
  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;

const OfferSection = () => {
  return (
    <Wrapper>
      <Content>
        <Title
          text="What We Offer?"
          size={42}
          margin="0 auto 60px"
          textAlign="center"
        />
        <AnimationContainer side="left" speed={600} startWhenShow>
          <CardContainer>
            <OfferCard
              img={web}
              text="Web Development"
              item="We build applications that work for your business and your users. Whether it’s a complex enterprise platform and backend database, a responsive web app, or a marketing site, our web solutions function smoothly and deliver a consistent experience to users across all platforms. Leveraging modern approaches to web development, we bring design, front-end, back-end, and architecture together to meet your business needs."
            />
            <OfferCard
              text="Mobile development"
              img={mobile}
              item="We take your idea to the next stage and accelerate your product's go-to-market building beautiful and scalable mobile applications with top performance using React. Native."
            />
            <OfferCard
              text="Custom CRM"
              img={crm}
              item="Once we have a full understanding of how we want to make your business run, we design, build, and implement in your organization a software solution that will power and enforce the envisioned processes and rules, along with collecting the data on how your organization performs so we can optimize and improve these processes further."
            />
            <OfferCard
              text="E-commerce"
              img={commerce}
              item="Our Magento and Shopify developers have expertise in creating customized  e-commerce stores for all kinds of businesses. We offer end-to-end setup services to launch and manage your online store successfully."
            />
            <OfferCard
              text="Crypto"
              img={crypto}
              item="Smart contracts, Cryptowallets, Crypto trading apps, NFT tokens built according to the latest and modern trends and technologies."
            />
            <OfferCard
              text="Web Application"
              img={app}
              item="Build your top E-commerce, Media and Entertainment or Social Network solutions with our help! The main advantage of PWA is the native-app user experience available in the browser. It also reduces the time and money spent on development as the same code runs across all the platforms and devices. PWA allows taking advantage of Google search and keeps working offline or with a poor network connection."
            />
          </CardContainer>
        </AnimationContainer>
      </Content>
    </Wrapper>
  );
};

export default OfferSection;
