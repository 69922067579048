import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {Button, CloseButton, Input, TextArea} from "../../UiKit";

const ModalWrapper = styled.div`
`;

const ModalPop =styled.div`
  background: #fff;
  border: 2px solid #aaa;
  border-radius: 5px;
  z-index: 999;
  max-width: 40%;
  width: 40%;
  margin: auto;
  padding: 1em 2em 2em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  @media (max-width: 720px) {
    max-width: 85%;
    width: 85%;
  }
`;

const ModalOverlay =styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  opacity: 0.75;
`;

const MetaBlock = styled.div`
	display: grid;
    grid-template-rows: 1fr ;
	grid-template-columns:  1fr;
	gap: 30px;
    margin-top: 20px;
	margin-bottom: 40px;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const MessageWrapper = styled.div`
	margin-bottom: 30px;
`;


const Modal = ({ visible, toggle, toApply, onSubmit, handleChange, name, setName, selectedFile, setSelectedFile }) => visible ? ReactDOM.createPortal(

    <ModalWrapper>
        <ModalPop role="dialog" aria-modal="true" >
            <CloseButton title="&times;" onClick={toggle}/>
            <MetaBlock>
                <Input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    onChange={handleChange}
                     value={toApply.email}
                />
                <MessageWrapper>
                    <TextArea
                        name="message"
                        placeholder="Your Message"
                         onChange={handleChange}
                         value={toApply.message}
                    />
                </MessageWrapper>
                {/*<FileUploader/>*/}
            </MetaBlock>
            <Button title="send" onClick={onSubmit && toggle}/>
        </ModalPop>
        <ModalOverlay></ModalOverlay>
    </ModalWrapper>, document.body
) : null;

export default Modal;