import React from "react";
import styled from "styled-components";
import { GoBackButton, PageHeader } from "../../elements";
import { PageContainer } from "../index";

const Content = styled.div`
	padding: 60px 20px 120px;
	max-width: 1210px;
	margin: 0 auto;
`;

const BackButtonWrapper = styled.div`
	max-width: 1210px;
	margin: 10px auto 0;
`;

const JobsDetailContainer = ({children, JobsName}) => {
    return (
        <PageContainer>
            <PageHeader title={JobsName} weight={300} />
            <BackButtonWrapper>
                <GoBackButton title="Back to Careers"/>
            </BackButtonWrapper>
            <Content>
                {children}
            </Content>
        </PageContainer>
    );
};

export default JobsDetailContainer;
